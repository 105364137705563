import { Component, OnInit, ChangeDetectorRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProcessingService } from '../processing.service';

import { MENU_ITEMS } from './pages-menu';

import { CdSessionService, Session } from '../theme-ext/services';

@Component({
  selector: 'ngx-pages',
  styleUrls: ['pages.component.scss'],
  template: `
    <div [nbSpinner]="processing"
                                      nbSpinnerStatus="info"
                                      nbSpinnerSize="large"
                                      nbSpinnerMessage="">
      <ngx-one-column-layout>
        <nb-menu [items]="menu"></nb-menu>
        <tenants-selection></tenants-selection>
        <router-outlet></router-outlet>
      </ngx-one-column-layout>
    </div>
  `,
})
export class PagesComponent implements OnInit {

  menu = MENU_ITEMS;

  static TOKEN_USER = 'token';

  processing: boolean = false;

  constructor(private sessionService: CdSessionService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private processingService: ProcessingService,
              private cd: ChangeDetectorRef
    ) {
    sessionService.subscribeLoggedIn(value => {
        if (sessionService.getLogin() != null) {
          const filteredItems = [];
          MENU_ITEMS.forEach((item) => {
            const filteredChildren = [];
            if (item.children) {
              item.children.forEach(childItem => {
                if (this.itemAllowed(childItem)) {
                  filteredChildren.push(childItem);
                }
              });
              item = Object.assign({}, item);
              item.children = filteredChildren;
            }
            if (this.itemAllowed(item)) {
              filteredItems.push(item);
            }
          });

          this.menu = filteredItems;
          this.setHomeUrl();
          if (!this.router.getCurrentNavigation() && this.router.url.indexOf('login') != -1) {
            this.router.navigate([this.getHomeUrl()]);
          }
        } else {
         let sessionStr = this.activatedRoute.snapshot.queryParamMap.get('session');
         let authInfoStr = this.activatedRoute.snapshot.queryParamMap.get('authInfo');
         let idTokenStr = this.activatedRoute.snapshot.queryParamMap.get('id_token');
         if (sessionStr && authInfoStr && idTokenStr) this.socialLogin(sessionStr, authInfoStr, idTokenStr);
       }
       this.clearUrl();
    });
  }

  ngOnInit() {
    this.processingService.stateObserver.subscribe( state => {
      this.processing = state;
      this.cd.detectChanges();
    });
  }

  private itemAllowed(menuItem) {
    let allowed = true;

    if (menuItem.permissionsRequired) {
      menuItem.permissionsRequired.forEach(pr => allowed = allowed && this.sessionService.hasPermission(pr));
    } else if (menuItem.isGroupingElement) {
      allowed = menuItem.children && menuItem.children.length > 0;
    }

    return allowed;
  }

  private setHomeUrl() {
    if (this.menu.length > 0 && this.menu[0].children && this.menu[0].children.length > 0) {
      return this.menu[0].children[0].home = true;
    }
  }

  private getHomeUrl() {
    if (this.menu.length > 0 && this.menu[0].children && this.menu[0].children.length > 0) {
      return this.menu[0].children[0].link;
    }
  }

  public static getDefaultHomeUrl() {
    return '/pages/homepage';
  }

  socialLogin(sessionStr: string, authInfoStr: string, idTokenStr: string): void {
    let session: Session = JSON.parse(sessionStr);
    session.authInfo = JSON.parse(authInfoStr);
    session.idToken = idTokenStr;
    this.sessionService.loginWithSession(session);
  }

  clearUrl() {
    var url = document.location.href;
    var urlComp = url.split("?");
    if (urlComp.length < 2) return;
    var params = urlComp[1].split("&");
    params = params.filter(p => p.indexOf("session") == -1 && p.indexOf("authInfo") == -1 && p.indexOf("id_token") == -1);
    var finalUrl = params.length ? urlComp[0] + "?" + params.join("&") : urlComp[0];
    window.history.pushState({}, "", finalUrl);
  }
}
