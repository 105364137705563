import {NbIconLibraries} from '@nebular/theme';
import {NgModule} from '@angular/core';

@NgModule()
export class CustomIconsModule {

    private name = 'CUSTOM_ICONS';
    private iconMap = {
      'download-snapshot': '<svg width="25px" height="25px" xmlns="http://www.w3.org/2000/svg" transform="rotate(180)">\n  <path fill="#222b45" transform="scale(0.025,0.025) translate(-50,20)" stroke="none" d="M747.302 789.332h-470.609c-11.699 0-20.693-8.92-20.693-20.508v-641.163c0-7.134 3.597-14.268 9.899-17.833s14.398-4.461 20.693-0.89l225.856 111.469 225.856-111.469c2.701-1.786 6.302-1.786 9.003-1.786 3.595 0 8.096 0.89 10.795 3.565 6.302 3.565 9.899 10.699 9.899 17.833v639.38c0 11.594-8.994 21.403-20.693 21.403l-0.004-0.001zM726.609 160.655l-205.161 101.658c-2.699 1.786-6.302 1.786-9.001 1.786s-6.302-0.89-9.001-1.786l-206.044-101.658v586.766h428.316v-586.766h0.89zM420.666 485.246h70.187v-69.557c0-11.595 9.001-20.508 20.695-20.508 11.699 0 20.693 8.919 20.693 20.508v69.557h70.187c11.699 0 20.693 8.919 20.693 20.508 0 11.595-9.001 20.508-20.693 20.508h-70.187v69.557c0 11.595-9.001 20.508-20.693 20.508-11.701 0-20.695-8.919-20.695-20.508v-69.557h-70.187c-11.699 0-20.693-8.919-20.693-20.508 0-10.699 8.994-20.508 20.693-20.508z"></svg>',
      'save-snapshot': '<svg width="25px" height="25px" xmlns="http://www.w3.org/2000/svg" transform="rotate(180)">\n  <path fill="#222b45" transform="scale(0.025,0.025) translate(-50,20)" stroke="none" d="M255.81 204.113v563.988h427.426v-271.353h42.477v292.635c0 11.754-9.508 21.283-21.239 21.283h-469.901c-11.731 0-21.239-9.529-21.239-21.283v-606.553c0-11.755 9.508-21.282 21.239-21.282h252.207v42.564h-230.97zM664.653 257.321c-8.994 0-16.284 7.305-16.284 16.316v28.555h-28.495c-8.992 0-16.284 7.305-16.284 16.316s7.292 16.318 16.284 16.318h28.495v28.553c0 9.011 7.29 16.318 16.284 16.318 8.992 0 16.282-7.307 16.282-16.318v-28.553h28.495c8.994 0 16.284-7.307 16.284-16.318s-7.29-16.316-16.284-16.316h-28.495v-28.555c0-9.011-7.29-16.316-16.282-16.316zM518.637 443.541v-336.887c0-17.218 19.341-27.311 33.419-17.436l111.836 78.447c0.457 0.32 1.065 0.32 1.521 0l111.836-78.447c14.078-9.875 33.419 0.218 33.419 17.436v336.887c0 11.755-9.508 21.284-21.239 21.284h-249.551c-11.731 0-21.239-9.53-21.239-21.284zM550.494 127.091v305.809h228.316v-305.809l-95.127 66.726c-11.426 8.015-26.635 8.015-38.061 0l-95.127-66.726zM330.146 656.367c0 11.755 9.508 21.284 21.237 21.284h234.95c11.731 0 21.239-9.53 21.239-21.284 0-11.753-9.508-21.282-21.239-21.282h-234.95c-11.729 0-21.237 9.53-21.237 21.282zM330.146 528.672c0 11.755 9.508 21.282 21.237 21.282h234.95c11.731 0 21.239-9.527 21.239-21.282 0-11.753-9.508-21.282-21.239-21.282h-234.95c-11.729 0-21.237 9.53-21.237 21.282zM330.146 400.977c0 11.755 9.508 21.282 21.237 21.282h96.9c11.731 0 21.239-9.527 21.239-21.282s-9.508-21.282-21.239-21.282h-96.9c-11.729 0-21.237 9.527-21.237 21.282z"></svg>',
      'drag-handle': '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 7L12 4L15 7H9Z" fill="#2B353B"/><path d="M15 17L12 20L9 17H15Z" fill="#2B353B"/><path d="M22 9H2V11H22V9Z" fill="#2B353B"/><path d="M22 13H2V15H22V13Z" fill="#2B353B"/></svg>'
    };
    constructor(private iconLibraries: NbIconLibraries) {
        this.iconLibraries.registerFontPack('nebular-icons');
        this.iconLibraries.registerSvgPack(this.name, this.iconMap);

    }

}
