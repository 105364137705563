import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Tenant } from '../tenants.service';

@Component({
  selector: 'tenants-selection-item',
  templateUrl: './selectionItem.component.html',
})
export class TenantsSelectionItemComponent {

  @Input()
  tenant: Tenant;

  @Output()
  select: EventEmitter<Tenant> = new EventEmitter<Tenant>();

  onSelect(tenant: Tenant): void {
    this.select.emit(tenant);
  }
}
