import { Component } from '@angular/core';

import { BaMsgCenterService } from './cdMsgCenter.service';

@Component({
  selector: 'cd-msg-center',
  providers: [BaMsgCenterService],
  styleUrls: ['./cdMsgCenter.scss'],
  templateUrl: './cdMsgCenter.html'
})
export class CdMsgCenter {

  public notifications: Array<Object>;
  public messages: Array<Object>;

  constructor(private _baMsgCenterService: BaMsgCenterService) {
    this.notifications = this._baMsgCenterService.getNotifications();
    this.messages = this._baMsgCenterService.getMessages();
  }

}
