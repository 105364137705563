import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cd-action',
  templateUrl: './cdAction.html',
  styleUrls: ['cdAction.component.scss'],
})
export class CdAction {
  @Input() icon: string;
  @Input() enabled: boolean = true;
  @Output() click = new EventEmitter();

  getIconClass(): string {
    if (this.icon) {
      return ' btn-icon';
    }
    return '';
  }
}
