import {HttpHeaders, HttpClient, HttpErrorResponse} from '@angular/common/http';
import 'rxjs/add/operator/toPromise';
import {CdSessionService} from './cdSession.service';
import {NeverObservable} from 'rxjs/observable/NeverObservable';
import {ErrorObservable} from 'rxjs/observable/ErrorObservable';
import {CdNotificationService} from "./cdNotificationService.service";
import { catchError } from 'rxjs/operators';
import {Observable} from 'rxjs';

const AUTHORIZATION_HEADER = 'Authorization';

export abstract class CdAbstractService {

  sessionService: CdSessionService;
  http: HttpClient;
  protected notificationService: CdNotificationService;

  protected get(url: string, options?: any): Promise<any> {
    let authOptions = this.authorize(options);
    return this.http.get(url, authOptions).pipe(catchError(this.errorHandler)).toPromise();
  }

  protected getObservable<T>(url: string, options?: any): Observable<any> {
    let authOptions = this.authorize(options);
    return this.http.get<T>(url, authOptions).pipe(catchError(this.errorHandler));
  }

  protected put(url: string, body: any, options?: any): Promise<any> {
    let authOptions = this.authorize(options);
    return this.http.put(url, body, authOptions).pipe(catchError(this.errorHandler)).toPromise();
  }

  protected post(url: string, body: any, options?: any): Promise<any> {
    let authOptions = this.authorize(options);

    return this.http.post(url, body, authOptions).pipe(catchError(this.errorHandler)).toPromise();
  }
  protected postObservable(url: string, body: any, options?: any): Observable<any> {
    let authOptions = this.authorize(options);

    return this.http.post(url, body, authOptions).pipe(catchError(this.errorHandler));
  }

  protected delete(url: string, options?: any): Promise<any> {
    let authOptions = this.authorize(options);
    return this.http.delete(url, authOptions).pipe(catchError(this.errorHandler)).toPromise();
  }

  protected buildUrl(urlTemplate: any, params: {[key: string]: string}) {
    let url = urlTemplate;
    for (let param in params) {
      if (params.hasOwnProperty(param)) {
        let value = params[param];
        url = url.replace('{' + param + '}', value);
      }
    }
    return url;
  }

  protected getWithoutAuthorization(url: string): Promise<any> {
    return this.http.get(url).pipe(catchError(this.errorHandler)).toPromise();
  }

  protected getIdMap(url, id: string) {
    let match = /.*{(.*)}.*/.exec(url);
    if (match == null) {
      throw new Error('can\'t find id \'' + id + '\' in url \’' + url + '\'');
    }
    let idParam = match[1];
    let idMap = {};
    idMap[idParam] = id;
    return idMap;
  }

  protected handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    if (error !== null && error !== undefined && error.message !== null && error.message !== undefined) {
      //this.notificationService.error(error.message);
    }
    return Promise.reject(error.message || error);
  }

  protected encodeParams(params: {[key: string]: string}): string {
    let encodedParams = new URLSearchParams();
    for (let param in params) {
      if (params.hasOwnProperty(param)) {
        let value = params[param];
        encodedParams.set(param, value);
      }
    }
    return encodedParams.toString();
  }

  private authorize(options: any) {
    if (options === undefined) {
      options = {
        headers: undefined
      };
    }
    let headers = options.headers;
    if (headers === undefined && this.sessionService !== undefined) {
      const authHeader = this.sessionService.getAuthorizationHeader();
      if (authHeader) {
        headers = new HttpHeaders().set(AUTHORIZATION_HEADER, authHeader);
        options.headers = headers;
      }
    }

    return options;
  }

  errorHandler = (err: HttpErrorResponse) => {
    /*if (err.status === 401 || err.status === 403) {
      if (this.sessionService !== undefined) {
        if (err.status === 401) {
          this.sessionService.redirectToLogin();
        } else {
          this.sessionService.redirectToHome();
        }
        return NeverObservable.create<ArrayBuffer>();
      }
    } else {*/
    console.log('caught exception in service call', err);
    return ErrorObservable.create(err);
    //}
  }
}
