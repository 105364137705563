
<div class="widgets">
  <div class="row">
    <div class="col-lg-4 col-md-12">
      <cd-card [title]="'Tenant Details'">
        <tenants-details-general [tenant]="tenant"></tenants-details-general>
      </cd-card>
    </div>
  </div>
</div>
