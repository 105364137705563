import { Component, Input } from '@angular/core';

import { Tenant } from '../tenants.service';


@Component({
  selector: 'tenants-details',
  templateUrl: './details.html',
})
export class Details {

  private _tenant: Tenant;

  get tenant(): Tenant {
    return this._tenant;
  }

  @Input()
  set tenant(tenant: Tenant) {
    this._tenant = tenant;
  }


  constructor() {
  }
}
