import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { CdSessionService, Session } from '../theme-ext/services';
import { Config } from '../config/config';

import { MENU_ITEMS } from './pages-menu';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private sessionService: CdSessionService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let url: string = state.url;
    return this.checkLogin(url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  checkLogin(url: string): boolean {
    if (!Config.env().securityEnabled) {
      return true;
    }
    if (this.sessionService.isLoggedIn()) {
      let itemAllowed = false;
      MENU_ITEMS.forEach((item) => {
        if (item.link == url) {
          itemAllowed = this.itemAllowed(item);
          return;
        }
        if (item.children) {
          item.children.forEach(childItem => {
            if (childItem.link == url) {
              itemAllowed = this.itemAllowed(childItem);
              return;
            }
          });
        }
      });

      if (!itemAllowed) {
        this.router.navigate(['/pages/homepage']);
        return false;
      }
      return true;
    }
    if (url.indexOf('/pages/homepage?') == 0 && url.indexOf('id_token')) {
      return true;
    }

    // Store the attempted URL for redirecting
    this.sessionService.redirectUrl = url;

    // Navigate to the login page with extras
    this.router.navigate(['/login']);
    return false;
  }

  private itemAllowed(menuItem) {
      let allowed = true;

      if (menuItem.permissionsRequired) {
        menuItem.permissionsRequired.forEach(pr => allowed = allowed && this.sessionService.hasPermission(pr));
      } else if (menuItem.isGroupingElement) {
        allowed = menuItem.children && menuItem.children.length > 0;
      }

      return allowed;
    }
}
