import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import { Router } from '@angular/router';
import { CdSessionService } from '../../../theme-ext/services';
import {CdSettingsProviderService} from "../../../theme-ext/services/cdSettingsProvider.service";

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any = null;
  LOG_OUT = 'Log out';
  TOKEN_USER = 'token';

  private _loggedInSubscription: Subscription;

  userMenu = [ { title: this.LOG_OUT } ];

  stageName ='';

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private router: Router,
              private cdSessionService: CdSessionService,
              private cdSettingsProviderService: CdSettingsProviderService) {

              this._loggedInSubscription = cdSessionService.subscribeLoggedIn(value => {
                if (cdSessionService.getLogin() != null) {
                  this.user = {
                    id: cdSessionService.getLogin()
                  };

                  if (this.user.id === this.TOKEN_USER) {
                    this.userMenu = [];
                  }
                } else {
                  this.user = null;
                }
              });
                this.menuService.onItemClick()
                .pipe(
                  takeUntil(this.destroy$)
                )
                .subscribe((event) => {
                  this.navigateLogout(event);
                });
    cdSettingsProviderService.getStageName().then((result) => {
      if (result != "PROD") {
        this.stageName = " - ";
      }
      this.stageName += result;
    });

  }

  ngOnInit() {

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);
  }

  ngOnDestroy() {
    this.cdSessionService.unsubscribeLoggedIn(this._loggedInSubscription);
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  navigateLogin() {
    this.router.navigate(['/login']);
  }

  navigateLogout(event) {
    if(event.item.title === this.LOG_OUT) {
      this.cdSessionService.logout();
      this.navigateLogin();
    }
  }

  get sessionExpired(): boolean {
    return !this.cdSessionService?.credentialsNonExpired;
  }

}
