import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input, OnChanges,
    Output, SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import {IColumns, LocalDataSource, Settings} from 'angular2-smart-table';


export class CdTableData {

  source: LocalDataSource = new LocalDataSource([{}]);
  private columns: any;

  constructor(columns: any) {
    this.columns = columns;
  }

  load(entries: any[]): void {
    this.source.load(entries);
  }

  add(entry: any): void {
    this.source.add(entry);
    this.source.refresh();
  }

  edit(entry: any, oldEntry: any): void {
    this.source.update(entry, oldEntry);
  }

  delete(entry: any) {
    this.source.remove(entry);
  }

  filter(query: string = ''): void {
    if(query === ''){
      console.log('performed refresh on datasource');
      this.source.setFilter([]);
      return;
    }

    let columns = this.columns;
    let filter = [];
    for (let column in columns) {
      filter.push({
        field: column,
        search: query
      });
    }
    this.source.setFilter(filter, false);
  }

  columnsFilter(query: string = '', columnNames: any): void {
    if (query === '') {
      console.log('performed refresh on datasource');
      this.source.setFilter([]);
      return;
    }

    const filter = [];
    for (const columnName of columnNames) {
      filter.push({
        field: columnName,
        search: query
      });
    }
    this.source.setFilter(filter, true);
  }

  goToPage(page: number) {
    this.source.setPage(page);
  }

  setColumns(columns: any) {
    this.columns = columns;
  }
}


@Component({
  template: `<div class="text-center"><cd-boolean [value]="value"></cd-boolean></div>`,
})
export class CdTableCellBoolean {
  @Input() value: boolean;
}

@Component({
  template: `<div class="text-center" [style.visibility]="!value && value !== false ? 'hidden' : 'visible'"><cd-boolean [value]="value"></cd-boolean></div>`,
})
export class CdTableCellTristate {
  @Input() value: boolean;
}

@Component({
  template: `<pre [innerHTML]="value"></pre>`,
})
export class CdTableCellPreformatted {
  @Input() value: string;
}


@Component({
  selector: 'cd-table',
  templateUrl: './cdTable.html',
  styleUrls: ['./cdTable.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CdTable implements OnChanges {
  @Input() data: CdTableData;

  @Output() select = new EventEmitter();
  @Output() add = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() compare = new EventEmitter();
  @Output() impersonate = new EventEmitter();
  @Output() copy = new EventEmitter();

  get columns(): any {
    return this.settings.columns;
  }

  ngOnChanges(changes:SimpleChanges) {
    console.log("TABLE DATA", changes, this.data.source.getAll());
  }

  @Input()
  set columns(columns: any) {
    for (let key in columns) {
      if (!columns.hasOwnProperty(key)) {
        continue;
      }
      let column = columns[key];
      if (column.type === 'boolean') {
        console.log('updating boolean column', key, column);
        column.type = 'custom';
        column.renderComponent = CdTableCellBoolean;
        column.compareFunction = this.booleanCompareFunction;
      }
      if (column.type === 'tristate') {
        column.type = 'custom';
        column.renderComponent = CdTableCellTristate;
        column.compareFunction = this.booleanCompareFunction;
      }
      if (column.type === 'preformatted') {
        column.type = 'custom';
        column.renderComponent = CdTableCellPreformatted;
        column.compareFunction = this.stringCompareFunction;
      }
      if (column.type === 'string') {
        column.compareFunction = this.stringCompareFunction;
      }
    }
    this.settings.columns = columns;
    this.settings = Object.assign({}, this.settings );
  }

  booleanCompareFunction = (dir, obj1, obj2) => {
    if (!obj1) return -1 * dir;
    return 1 * dir;
  }

  stringCompareFunction = (dir, obj1, obj2) => {
    if (!obj1) return 1;
    if (obj1 && !obj2) return -1;
    return obj1.localeCompare(obj2) * -1 * dir;
  }

  @Input()
  set canWrite(canWrite: boolean) {
    this.settings.actions.edit = canWrite;
    this.settings.actions.delete = canWrite;
  }

  @Input()
  set canEdit(canEdit:boolean) {
    this.settings.actions.edit = canEdit;
  }

  @Input()
  set canDelete(canDelete:boolean) {
    this.settings.actions.delete = canDelete;
  }

  @Input()
  set canCompare(canCompare:boolean) {
    this.settings.actions.custom = [{ name: 'compare', title: '<i class="nb-compose" title="Compare"></i>'}];
  }

  @Input()
  set canImpersonate(canImpersonate:boolean) {
    this.settings.actions.custom = [{ name: 'impersonate', title: '<i class="nb-person" title="Impersonate"></i>'}];
  }

  @Input()
  set canCopy(canCopy:boolean) {
    this.settings.actions.custom = [{ name: 'copy', title: '<i class="nb-compose" title="Copy"></i>'}];
  }


  settings: Settings | any = {
    mode: 'external',
    hideSubHeader: true,
    actions: {
      position: 'right',
      add: true,
      edit: true,
      delete: true,
      custom: [],
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
    },
    columns: {} as IColumns
  };

  @Input()
  set actionColumnVisible(columnVisible: boolean) {
    this.settings.actions.add = columnVisible;
    this.settings.actions.edit = columnVisible;
    this.settings.actions.delete = columnVisible;
  }

  onSelect(event): void {
    console.log('cd-table: select', event);
    this.select.emit(event.data);
  }

  onAdd(event): void {
    console.log('cd-table: add', event);
    this.add.emit();
  }

  onEdit(event): void {
    console.log('cd-table: edit', event);
    this.edit.emit(event.data);
  }

  onDelete(event): void {
    console.log('cd-table: delete', event);
    this.delete.emit(event.data);
  }

  onCustom(event): void {
    this[event.action].emit(event.data);
  }
}
