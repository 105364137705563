import {Component, Input, ViewEncapsulation} from '@angular/core';


@Component({
  selector: 'cd-card',
  templateUrl: './cdCard.html',
  styleUrls: ['./cdCard.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CdCard {

  @Input() title: string;
}
