import { Component } from '@angular/core';


import { GlobalState } from '../../../global.state';
import { CdSessionService } from '../../services/cdSession.service';

@Component({
  selector: 'cd-page-top',
  templateUrl: './cdPageTop.html',
  styleUrls: ['./cdPageTop.scss']
})
export class CdPageTop {

  public isScrolled: boolean = false;
  public isMenuCollapsed: boolean = false;

  constructor(private _state: GlobalState, private sessionService: CdSessionService) {
    this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
      this.isMenuCollapsed = isCollapsed;
    });
  }

  public toggleMenu() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
    this._state.notifyDataChanged('menu.isCollapsed', this.isMenuCollapsed);
    return false;
  }

  public scrolledChanged(isScrolled) {
    this.isScrolled = isScrolled;
  }

  public isLoggedIn(): boolean {
    return this.sessionService.isLoggedIn();
  }

  public getLogin(): string {
    return this.sessionService.getLogin();
  }

  public logout(): void {
    this.sessionService.logout();
  }
}
