<dl class="row" *ngIf="tenant">
  <dt class="col-4" style="text-align: right;">Id</dt>
  <dd class="col-8">{{tenant.id}}</dd>

  <dt class="col-4" style="text-align: right;">Name</dt>
  <dd class="col-8">{{tenant.name}}</dd>

  <dt class="col-4" style="text-align: right;">Description</dt>
  <dd class="col-8">{{tenant.description}}</dd>

  <dt class="col-4" style="text-align: right;">Builtin</dt>
  <dd class="col-8"><cd-boolean [value]="tenant.builtin"></cd-boolean></dd>

  <dt class="col-4" style="text-align: right;">Changed</dt>
  <dd class="col-8"><cd-boolean [value]="tenant.changed"></cd-boolean></dd>
</dl>
