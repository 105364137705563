import { Component } from '@angular/core';
import { CD_MODAL_OPTIONS } from '../cdModal/cdModal.component';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'cd-modal-confirm',
  templateUrl: './cdModalConfirm.html',
})
export class CdModalConfirm {
  title: string;
  message: string;

  static open(modal: NgbModal, title: string, message: string): Promise<any> {
    let activeModal = modal.open(CdModalConfirm, CD_MODAL_OPTIONS);
    let instance: CdModalConfirm = activeModal.componentInstance;
    instance.title = title;
    instance.message = message;
    console.log('open confirm', activeModal);
    return activeModal.result;
  }

  constructor(private activeModal: NgbActiveModal) {
  }

  onOk(): void {
    this.activeModal.close();
  }

  onCancel(): void {
    this.activeModal.dismiss();
  }
}
