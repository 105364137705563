import { Injectable } from '@angular/core';
import { Config } from '../../config/config';
import { CdEntityService } from '../../theme-ext/services/cdEntity.service';
import {CdNotificationService, CdSessionService} from '../../theme-ext/services';
import {AuthInfo} from '../../theme-ext/services/cdAuth.service';
import {Tenant} from "../admin/tenants/tenants.service";


export class JwtUser {
  userId: string;
  lastName: string;
  brand: string;
  countryCode: string;
  language: string;
  market: string;
  domain: string;
}

const JWT: string = 'jwt';

@Injectable()
export class JwtUserService extends CdEntityService<JwtUser> {

  constructor(sessionService: CdSessionService, notificationService: CdNotificationService) {
    super(sessionService, notificationService);
  }

  getUser(token: string): Promise<JwtUser> {
    return super._load(Config.env().aemJwtUser + '?token=' + token);
  }

  getLoginAuthentication(token: string): Promise<AuthInfo> {
    const jwtAuthenticationUrl = Config.env().aemJwtToken + '?token=' + token;
    return this.getNoAuth(jwtAuthenticationUrl)
        .then((response: any) => {
          // console.log('auth-service ok', response);
          const r = response as AuthInfo;
          return r;
        })
        .catch((response) => {
          // console.log('auth-service NOK', response);
          return Promise.reject(response as AuthInfo);
        });
  }

  getSocialLoginAuthentication(token: string): Promise<AuthInfo> {
    const jwtAuthenticationUrl = Config.env().sessionLoginSocialToken + '?social_token=' + token;
    return this.getNoAuth(jwtAuthenticationUrl)
        .then((response: any) => {
          // console.log('auth-service ok', response);
          const r = response as AuthInfo;
          return r;
        })
        .catch((response) => {
          // console.log('auth-service NOK', response);
          return Promise.reject(response as AuthInfo);
        });
  }

  getTenantFromToken(token: string): Promise<Tenant> {
    return super._load(Config.env().aemJwtTenant + '?token=' + token);
  }

  setAemToken(token: string) {
    sessionStorage.setItem(JWT, token);
  }

  getAemToken(): string {
    return sessionStorage.getItem(JWT);
  }

}
