import { NbToastrService, NbComponentStatus } from '@nebular/theme';
import {Injectable} from "@angular/core";

@Injectable()
export class CdNotificationService {
  private settings: object;


  constructor(private toastrService: NbToastrService) {
  }

  ngOnInit() {
    this.settings = {
    };
  }

  success(content: string): void {
    this.toastrService.show('success', content, { status: 'success' });
  }

  error(content: string): void {
    this.toastrService.show('danger', content, { status: 'danger' });
  }

  alert(content: string): void {
    this.toastrService.show('primary', content, { status: 'primary' });
  }

  warn(content: string): void {
    this.toastrService.show('warning', content, { status: 'warning' });
  }

  info(content: string): void {
    this.toastrService.show('info', content, { status: 'info' });
  }
}
