import { Component, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CD_MODAL_OPTIONS, CdModalConfirm, CdTableData } from '../../../../theme-ext/components';

import { Tenant, TenantsService } from '../tenants.service';
import { Edit } from './edit/edit.component';

const columns = {
  id: {
    title: 'ID',
    type: 'string'
  },
  name: {
    title: 'Name',
    type: 'string'
  },
  description: {
    title: 'Description',
    type: 'string'
  },
  builtin: {
    title: 'Builtin',
    type: 'boolean'
  },
  changed: {
    title: 'Changed',
    type: 'boolean'
  }
};

@Component({
  selector: 'tenants-overview',
  templateUrl: './overview.html',
})
export class Overview {
  @Output() select = new EventEmitter();

  data: CdTableData = new CdTableData(columns);

  get columns(): any {
    return columns;
  }

  constructor(protected service: TenantsService, private modalService: NgbModal) {
    this.service.getOverview().then((tenants) => {
      // console.log('tenant-overview: load data', tenants);
      this.data.load(tenants);
    });
  }

  onSearch(query: string = ''): void {
    this.data.filter(query);
  }

  onSelect(tenant: Tenant): void {
    console.log('tenant-overview: row-select', event);
    this.select.emit(tenant);
  }

  onAdd(): void {
    console.log('tenant-overview: add');
    this.openEdit('Create Tenant', null);
  }

  onEdit(tenant: Tenant): void {
    console.log('tenant-overview: edit', event);
    this.openEdit('Edit Tenant', tenant);
  }

  openEdit(title: string, tenant: Tenant): void {
    let create = tenant === null;
    if (create) {
      tenant = new Tenant();
    }
    let self = this;
    let activeModal = this.modalService.open(Edit, CD_MODAL_OPTIONS);
    let instance: Edit = activeModal.componentInstance;
    console.log('tenant-edit', instance, self);
    instance.create = create;
    instance.init(title, tenant, self.service);
    activeModal.result.then(function (editedTenant: Tenant) {
      console.log('modal-then', editedTenant);
      if (create) {
        self.prepareTenant(editedTenant);
      }
      self.service.saveTenant(editedTenant, editedTenant.id).then((savedTenant: Tenant) => {
        console.log('saved tenant', create, savedTenant);
        if (create) {
          self.data.add(savedTenant);
        } else {
          self.data.edit(tenant, savedTenant);
        }
      });
    });
  }

  onDelete(tenant: Tenant): void {
    CdModalConfirm.open(this.modalService, 'Delete Tenant',
      'Please confirm to delete the tenant \'' + tenant.id + '\''
    ).then(() => {
      console.log('deleted tenant', tenant);
      this.service.deleteTenant(tenant).then(() => {
        console.log('deleted tenant', tenant);
        this.data.delete(tenant);
      });
    }).catch(() => {
      console.log('delete tenant cancelled');
    });
  }

  /**
   * Setup full id using currently selected tenant.
   * @param editedTenant
   */
  private prepareTenant(editedTenant: Tenant): void {
    let tenantId = editedTenant.name;
    if (this.service.selectedTenant) {
      tenantId = this.service.selectedTenant.id + '/' + tenantId;
    }
    console.log('prepareTenant', tenantId, this.service.selectedTenant);
    editedTenant.id = tenantId;
  }

}
