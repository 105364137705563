<cd-modal [title]="title" (close)="onCancel()">
  <form [formGroup]="formGroup" novalidate>
    <cd-control label="Tenant Id" for="tenantId" [errors]="errors" required="true">
      <input type="text" class="form-control" id="tenantId" formControlName="tenantId">
    </cd-control>
    <cd-control label="Name" for="name" [errors]="errors" required="true">
      <input type="text" class="form-control" id="name" formControlName="name">
    </cd-control>
    <cd-control label="Description" for="description" [errors]="errors">
      <textarea type="text" class="form-control" id="description" formControlName="description"></textarea>
    </cd-control>
    <cd-control label="Builtin" for="builtin" [errors]="errors">
      <input type="checkbox" class="form-control" id="builtin" formControlName="builtin">
    </cd-control>
    <cd-control label="Changed" for="changed" [errors]="errors">
      <input type="checkbox" class="form-control" id="changed" formControlName="changed">
    </cd-control>
  </form>

  <footer>
    <button type="submit" (click)="onSubmit()" class="btn btn-success"
            [disabled]="formGroup.pristine || formGroup.invalid">Save</button> &nbsp;
    <button type="reset" (click)="onCancel()" class="btn btn-danger">Cancel</button>
  </footer>
</cd-modal>
