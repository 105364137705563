<div class="widgets">

  <div class="row">
    <cd-card [title]="'Tenants Overview'">
      <header>
        <cd-search-action (search)="onSearch($event)"></cd-search-action>
        <cd-action icon="fa fa-plus" (click)="onAdd()" [enabled]="'TENANT_WRITE'|cdPermission">Add Tenant</cd-action>
      </header>
      <cd-table [columns]="columns" [data]="data" (select)="onSelect($event)" (edit)="onEdit($event)" (delete)="onDelete($event)" [canWrite]="'TENANT_WRITE'|cdPermission"></cd-table>
    </cd-card>
  </div>

</div>
