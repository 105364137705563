<nb-card>
  <nb-card-header>
      <div class="header-content">
        <div>
        {{title}}
      </div>
      <div class="header-actions">
        <ng-content select="header"></ng-content>
      </div>
    </div>
  </nb-card-header>

  <nb-card-body>
    <ng-content></ng-content>
  </nb-card-body>
</nb-card>

