import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Router} from '@angular/router';
import 'rxjs/add/operator/toPromise';
import {Config} from '../../config/config';
import {User} from '../../pages/admin/users/users.service';
import {CdAbstractService} from './cdAbstractService.service';
import {AuthInfo} from './cdAuth.service';
import {CdNotificationService} from "./cdNotificationService.service";
import {BehaviorSubject, Subscription} from "rxjs";
import {Tenant} from "../../pages/admin/tenants/tenants.service";
import {PagesComponent} from "../../pages/pages.component";


export class Session {
  username: string;
  idToken?: string;
  authInfo: AuthInfo;
  permissions: string;
}

const AUTHORIZATION_HEADER = 'Authorization';
const ID_TOKEN_HEADER = 'id_token';

const PDH_SESSION: string = 'PDH_SESSION';
const TOKEN_USER = 'token';

@Injectable()
export class CdSessionService extends CdAbstractService {

  redirectUrl: string;

  private _session: Session = undefined;

  private _isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  private _isUserLoggedInObservable = this._isUserLoggedIn.asObservable();

  private _isSessionExpired: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _isSessionExpiredObservable = this._isSessionExpired.asObservable();

  constructor(http: HttpClient, private router: Router, notificationService: CdNotificationService) {
    super();
    this.sessionService = this;
    this.http = http;
    this.notificationService = notificationService;
    if (!Config.env().securityEnabled) {
      sessionStorage.removeItem(PDH_SESSION);
    }
  }

  login(userId: string, authInfo: AuthInfo): any {
    let self = this;
    let loginUrl = Config.env().sessionLoginUrl;

    this.session = {
      username: null,
      authInfo: authInfo,
      permissions: null
    };

    let headers = new HttpHeaders().set('Authorization', this.getAuthorizationHeader());

    let options = {
      headers: headers
    };

    return this.get(loginUrl, options)
      .then((response: any) => {
        let session = response as Session;
        session.authInfo = authInfo;
        //console.log('login-response', self.session);
        session.permissions = btoa(session.username) + btoa(btoa(session.permissions));
        self.session = session;
        self.credentialsNonExpired = true;
        self._isUserLoggedIn.next(true);
        if (this.redirectUrl) {
          this.router.navigate([this.redirectUrl]);
        }
        return session;
      })
      .catch(response => {
        this.logout();
        throw response;
      });
  }

  impersonate(userId: string): any {
    let self = this;
    let impersonateUrl = this.buildUrl(Config.env().usersImpersonateServiceUrl, {
      impersonateUserId: userId
    });

    let headers = new HttpHeaders().set('Authorization', this.getAuthorizationHeader());

    let options = {
      headers: headers
    };

    return this.get(impersonateUrl, options)
        .then((response: any) => {
          self._isUserLoggedIn.next(false);

          let session = response.authenticatedUser as Session;
          session.authInfo = response.authInfo;
          //console.log('login-response', self.session);
          session.permissions = btoa(session.username) + btoa(btoa(session.permissions));
          self.session = session;
          self.credentialsNonExpired = true;
          setTimeout(() => { self._isUserLoggedIn.next(true) }, 0);
          this.router.navigate([PagesComponent.getDefaultHomeUrl()]);
          return session;
        })
        .catch(response => {
          this.logout();
          throw response;
        });
  }

  loginWithSession(session: Session) {
    session.permissions = btoa(session.username) + btoa(btoa(session.permissions));
    this.session = session;
    this._isUserLoggedIn.next(true);
  }

  logout(): void {
    sessionStorage.removeItem(PDH_SESSION);

    let idToken = this.session ? this.session.idToken : undefined;
    if (!idToken) {
      this.get(Config.env().sessionLogoutUrl);
      this._isUserLoggedIn.next(false);
    }
    this._session = undefined;
    this._isUserLoggedIn.next(false);
    if (idToken) {
      let logoutUrl = this.buildUrl(Config.env().oauth2LogoutUrl, {
          idToken: idToken
      });
      window.location.replace(logoutUrl);
    }
  }

  redirectToLogin(): void {
    if (!this.session || !this.session.username) return;
    if (this.session && this.session.username == TOKEN_USER) {
      this.credentialsNonExpired = false;
    } else {
      this.router.navigate(['/login']);
    }
  }

  redirectToHome(): void {
    this.router.navigate(['/']);
  }

  isLoggedIn(): boolean {
    if (!Config.env().securityEnabled) {
      return true;
    }
    return this.session !== undefined;
  }

  getLogin(): string {
    if (this.session === undefined) {
      return undefined;
    }
    return this.session.username;
  }

  getAuthorizationHeader() {
    if (this.session && this.session.authInfo) {
      return this.session.authInfo.token_type + ' ' + this.session.authInfo.access_token;
    }
    return undefined;
  }

  getIdTokenHeader() {
    if (this.session && this.session.idToken) {
      return this.session.idToken;
    }
    return undefined;
  }

  hasPermission(permission: string) {
    if (!Config.env().securityEnabled) {
      return true;
    }
    if (this.session === undefined) {
      return false;
    }

    let permissions: any = this.session.permissions.replace(btoa(this.session.username), "");
    try {
      permissions = atob(atob(permissions)).split(",");
    } catch (e) {
      return false
    }
    return permissions.some(p => p === permission || p === 'ALL');
  }

  clearSession() {
    this._session = undefined;
    sessionStorage.clear();
  }

  private get session(): Session {
    if (this._session) {
      return this._session;
    }

    let item = sessionStorage.getItem(PDH_SESSION);
    if (item !== null) {
      this._session = JSON.parse(item);
    } else {
      this._session = undefined;
    }
    return this._session;
  }

  private set session(session: Session) {
    this._session = session;
    if (session !== undefined) {
      sessionStorage.setItem(PDH_SESSION, JSON.stringify(this.session));
    } else {
      sessionStorage.removeItem(PDH_SESSION);
    }
  }

  subscribeLoggedIn(callback: (value: boolean) => void): Subscription {
    let sub = this._isUserLoggedInObservable.subscribe(callback);
    return sub;
  }

  unsubscribeLoggedIn(subscription: Subscription) {
    subscription.unsubscribe();
  }

  subscribeSessionExpired(callback: (value: boolean) => void): Subscription {
    let sub = this._isSessionExpiredObservable.subscribe(callback);
    return sub;
  }

  unsubscribeSessionExpired(subscription: Subscription) {
    subscription.unsubscribe();
  }

  set credentialsNonExpired(credentialsNonExpired) {
    if (!this.session) return;
    if (this.session['credentialsNonExpired'] != credentialsNonExpired && !credentialsNonExpired) {
      this._isSessionExpired.next(true);
    }
    this.session['credentialsNonExpired'] = credentialsNonExpired;

  }

  get credentialsNonExpired(): boolean {
    if (!this.session) return false;
    return this.session['credentialsNonExpired'];
  }

  isNormalLoginEnabled(): Promise<boolean> {
    return this.getWithoutAuthorization(Config.env().sessionNormalLoginEnabled);
  }

}
