<div class="cd-tree-grid" [class.visualizer]="isVisualizer">
<table [nbTreeGrid]="dataSource" [nbSort]="dataSource" (sort)="updateSort($event)">

  <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef="allColumnsPropertiesOnly"></tr>
  <tr class="selection" [ngClass]="{'selected': row.data.id === selectedRow, 'background-highlighted': row.data.manual}" nbTreeGridRow *nbTreeGridRowDef="let row; columns: allColumnsPropertiesOnly" (click)="selectAction(row.data)"></tr>

  <ng-container *ngFor="let column of expanderColumn; let index = index"
      [nbTreeGridColumnDef]="column.property">
    <th nbTreeGridHeaderCell [nbSortHeader]="getSortDirection(column.property)" *nbTreeGridHeaderCellDef>
      {{column.title}}
    </th>
    <td nbTreeGridCell (click)="toggleAction(row)" *nbTreeGridCellDef="let row" [ngClass]="{'highlighted': row.data.overwritten && !row.data.manual, 'grey-text': row.data.blacklisted}">
      <nb-tree-grid-row-toggle *ngIf="row.data[expanderRow] == true"
                [expanded]="row.expanded" (click)="toggleAction(row)">
      </nb-tree-grid-row-toggle>
      {{ (!row.data[expanderRow] && column.labelFunction) ? column.labelFunction(row.data) : row.data[column.property] }}
    </td>
  </ng-container>

  <ng-container *ngFor="let column of columns; let index = index"
                [nbTreeGridColumnDef]="column.property"
                [showOn]="getShowOn(index)">
    <th nbTreeGridHeaderCell [nbSortHeader]="getSortDirection(column.property)" *nbTreeGridHeaderCellDef>
      {{column.title}}
    </th>
    <td nbTreeGridCell (click)="toggleAction(row)" [ngClass]="{'highlighted': row.data.overwritten && !row.data.manual, 'grey-text': row.data.blacklisted}"
        *nbTreeGridCellDef="let row">{{ column.labelFunction ? column.labelFunction(row.data) : (row.data[column.property] || '') }}</td>
  </ng-container>

  <ng-container *ngFor="let column of actionColumns; let index = index"
                [nbTreeGridColumnDef]="column.property"
                [showOn]="getShowOn(index)">
    <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef>
      <div class="header-div">
        {{column.title}}
        <i *ngIf="canAddRow" class="ng2-smart-action ng2-smart-action-add-add ng-star-inserted nb-plus-circled" (click)="addAction($event)"></i>
      </div>
    </th>
    <td nbTreeGridCell (click)="toggleAction(row)" *nbTreeGridCellDef="let row">
      <nb-icon *ngIf="row.data[addChildRow] == true" title="Add" icon="nb-plus-circled" pack="nebular-icons" class="ng2-smart-action" (click)="addAction($event, row.data)"></nb-icon>
      <nb-icon *ngIf="row.data[canWriteRow]" title="Edit" icon="nb-edit" pack="nebular-icons" class="ng2-smart-action" (click)="editAction($event, row.data)"></nb-icon>
      <nb-icon *ngIf="row.data[canWriteRow] && !hideDelete" title="Delete" icon="nb-trash" pack="nebular-icons" class="ng2-smart-action" (click)="deleteAction($event, row.data)"></nb-icon>
      <nb-icon *ngIf="row.data[canWriteRow] && !hideDuplicate" title="Duplicate" icon="copy-outline" pack="eva" class="ng2-smart-action" (click)="duplicateAction($event, row.data)"></nb-icon>
      <nb-icon *ngIf="row.data[addChildRow] == true && excelDownloadEnabled && hasChildEntities(row.data)" title="Download model variants as Excel" icon="download-outline" pack="eva" class="ng2-smart-action" (click)="excelDownloadAction($event, row.data)"></nb-icon>
      <nb-icon *ngIf="row.data[addChildRow] == true && !shouldHideSnapshotUI(row.data) && hasChildEntities(row.data)" title="Save current model variant configurations as snapshot" icon="download-snapshot" pack="CUSTOM_ICONS" class="ng2-smart-action" (click)="saveSnapshotAction($event, row.data)"></nb-icon>
      <nb-icon *ngIf="row.data[addChildRow] == true && !shouldHideSnapshotUI(row.data) && shouldShowSnapshotDownloadFn(row.data)" title="Choose a snapshot to compare" icon="save-snapshot" pack="CUSTOM_ICONS" class="ng2-smart-action" (click)="openSnapshotModalAction($event, row.data)"></nb-icon>
      <nb-icon *ngIf="row.data[canWriteRow] && undoEnabled && !shouldHideUndo(row.data)" title="Undo" [icon]="!row.data.manual ?'nb-arrow-retweet': 'nb-trash'" pack="nebular-icons" class="ng2-smart-action" (click)="undoAction($event, row.data)"></nb-icon>
      <nb-icon *ngIf="row.data[canWriteRow] && deleteAssociationAllowed(row.data)" title="Undo" icon="nb-trash" pack="nebular-icons" class="ng2-smart-action" (click)="deleteAssociationAction($event, row.data)"></nb-icon>
      <nb-icon *ngIf="row.data[canWriteRow] && blacklistEnabled" [title]="row.data.blacklisted ? 'Remove from blacklist' : 'Blacklist'" [icon]="!row.data.blacklisted ? 'eye-off-outline' : 'eye-outline'" pack="eva" class="ng2-smart-action" (click)="blacklistAction($event, row.data)"></nb-icon>
      <nb-icon *ngIf="useToggleEnabled && showUseToggle(row.data)" title="Compare" icon="swap-outline" pack="eva" class="ng2-smart-action" (click)="compareAction($event, row.data)"></nb-icon>
      <nb-toggle *ngIf="useToggleEnabled && showUseToggle(row.data)" [(checked)]="row.data.useOverwrittenData" (checkedChange)="useToggleAction($event, row.data)" title="Use overwritten technical data"></nb-toggle>
    </td>
  </ng-container>

</table>
</div>
