import { NbMenuItem } from '@nebular/theme';

export class NbMenuItemWithPermissions extends NbMenuItem {
  permissionsRequired?: string[];
  children?: NbMenuItemWithPermissions[];
  isGroupingElement?: boolean;
}

export const MENU_ITEMS: NbMenuItemWithPermissions[] = [
  {
    title: 'Home',
    icon: 'home-outline',
    link: '/pages/homepage',
  },
  {
    title: 'Data',
    icon: 'cube-outline',
    isGroupingElement: true,
    children: [
      {
        title: 'Feed',
        icon: 'cast-outline',
        link: '/pages/feed',
        permissionsRequired: ['FEED_READ', 'TENANT_READ'],
      },
      {
        title: 'Datasets',
        icon: 'archive-outline',
        link: '/pages/datasets',
        permissionsRequired: ['DATASET_READ', 'DATASET_WRITE'],
      },
      {
        title: 'Entities',
        icon: 'layers-outline',
        link: '/pages/entities',
        permissionsRequired: ['ENTITY_READ', 'ENTITY_WRITE', 'TENANT_READ'],
      },
      {
        title: 'Resources',
        icon: 'file',
        link: '/pages/resources',
        permissionsRequired: ['RESOURCE_READ', 'RESOURCE_WRITE'],
      },
      {
        title: 'Schemas',
        icon: 'cube',
        link: '/pages/schemas',
        permissionsRequired: ['SCHEMA_READ', 'SCHEMA_WRITE'],
      },
      {
        title: 'Scheduler',
        icon: 'close',
        link: '/pages/scheduler',
        permissionsRequired: ['JOB_STATUS_READ', 'JOB_STATUS_WRITE', 'TENANT_READ'],
      },
      {
        title: 'Cache Clear',
        icon: 'sync-outline',
        link: '/pages/cache',
        permissionsRequired: ['CACHE_CLEAR', 'TENANT_READ'],
      },
     /*  {
         title: 'PGE',
         icon: 'sync-outline',
         link: '/pages/pge',
         permissionsRequired: ['TENANT_READ'],
      }, */
      {
        title: 'Extended Data',
        icon: 'calendar-outline',
        link: '/pages/extended',
        permissionsRequired: ['EXTENDED_DATA_READ'],
      },
    ],
  },
  {
    title: 'Administration',
    icon: 'settings-2-outline',
    isGroupingElement: true,
    children: [
      {
        title: 'Events',
        icon: 'flash',
        link: '/pages/admin/events',
        permissionsRequired: ['EVENT_READ', 'EVENT_WRITE'],
      },
      {
        title: 'Tenants',
        icon: 'folder',
        link: '/pages/admin/tenants',
        permissionsRequired: ['TENANT_READ', 'TENANT_WRITE'],
      },
      {
        title: 'Settings',
        icon: 'menu-outline',
        link: '/pages/admin/settings',
        permissionsRequired: ['SETTING_READ', 'TENANT_READ'],
      },
      {
        title: 'UCP Settings Comparison',
        icon: 'menu-outline',
        link: '/pages/admin/settings_comparison',
        permissionsRequired: ['SETTING_READ', 'TENANT_READ'],
      },
      {
        title: 'Users',
        icon: 'people',
        link: '/pages/admin/users',
        permissionsRequired: ['USER_READ', 'USER_WRITE'],
      },
      {
        title: 'Roles',
        icon: 'shield',
        link: '/pages/admin/roles',
        permissionsRequired: ['ROLE_READ', 'ROLE_WRITE'],
      },
    ],
  },
  {
    title: 'Vehicle Tree',
    icon: 'car-outline',
    isGroupingElement: true,
    children: [
      {
        title: 'Vehicle Tree Maintenance',
        icon: 'edit-2-outline',
        link: '/pages/vehicletree',
        permissionsRequired: ['OVERWRITE_DATA_READ', 'OVERWRITE_DATA_WRITE', 'TENANT_READ'],
      },
      {
        title: 'Vehicle Tree Dashboard',
        icon: 'edit-2-outline',
        link: '/pages/vehicletreedashboard',
        permissionsRequired: ['OVERWRITE_DATA_READ','TENANT_READ'],
      },
      {
        title: 'Vehicle Tree Feed',
        icon: 'edit-2-outline',
        link: '/pages/vehicletreefeed',
        permissionsRequired: ['OVERWRITE_DATA_FEED'],
      },
      {
        title: 'eRange Maintenance',
        icon: 'edit-2-outline',
        link: '/pages/erange',
        permissionsRequired: ['ERANGE_READ', 'ERANGE_WRITE'],
      },
    ],
  },
  {
    title: 'Precon',
    icon: 'car-outline',
    isGroupingElement: true,
    children: [
      {
        title: 'PreCon Feed',
        icon: 'cast-outline',
        link: '/pages/precon/feed',
        permissionsRequired: ['PRECONDATA_FEED'],
      },
      {
        title: 'PreCon Vehicles',
        icon: 'cast-outline',
        link: '/pages/precon/vehicles',
        permissionsRequired: ['PRECONDATA_READ', 'PRECONDATA_WRITE', 'TENANT_READ'],
      },
      {
        title: 'PreCon Dashboard',
        icon: 'cast-outline',
        link: '/pages/precon/dashboard',
        permissionsRequired: ['PRECONDATA_READ', 'PRECONDATA_WRITE', 'TENANT_READ'],
      },
    ],
  },
    /*{
      title: 'ISM',
      icon: 'car-outline',
      isGroupingElement: true,
      children: [
        {
          title: 'ISM Packages',
          icon: 'edit-2-outline',
          link: '/pages/ismpackages',
          permissionsRequired: ['ISM_READ', 'TENANT_READ'],
        },
        {
          title: 'ISM Options',
          icon: 'edit-2-outline',
          link: '/pages/ismoptions',
          permissionsRequired: ['ISM_READ', 'TENANT_READ'],
        },
      ],
    },*/
  {
    title: 'Visualizer',
    icon: 'color-palette-outline',
    isGroupingElement: true,
    link: '/pages/visualizer/configurations',
    permissionsRequired: ['VISUALIZERDATA_READ'],
  },
];
