<div class="page-top clearfix" baScrollPosition maxHeight="50" (scrollChange)="scrolledChanged($event)"
     [ngClass]="{scrolled: isScrolled}">
  <a routerLink="/pages/dashboard" class="al-logo clearfix"><span>pdh</span>-admin</a>
  <a href (click)="toggleMenu()" class="collapse-menu-link ion-navicon"></a>
<!--
  <div class="search">
    <i class="ion-ios-search-strong" ng-click="startSearch()"></i>
    <input id="searchInput" type="text" placeholder="Search for...">
  </div>
-->
  <div class="user-profile clearfix">
    <div class="al-user-profile" *ngIf="!isLoggedIn()">
      <button routerLink="/login" class="btn btn-primary">Login</button>
    </div>
    <div class="dropdown al-user-profile" *ngIf="isLoggedIn()">
      <a class="profile-toggle-link dropdown-toggle" id="user-profile-dd" data-toggle="dropdown" aria-expanded="false">
        <i class="fa fa-user"></i> {{getLogin()}}
      </a>
      <ul class="dropdown-menu top-dropdown-menu profile-dropdown" aria-labelledby="user-profile-dd">
        <!--
        <li class="dropdown-item"><a href><i class="fa fa-user"></i>Profile</a></li>
        <li class="dropdown-item"><a href><i class="fa fa-cog"></i>Settings</a></li>
        -->
        <li class="dropdown-item"><a (click)="logout()" class="signout"><i class="fa fa-power-off"></i>Sign out</a></li>
      </ul>
    </div>
    <!--
    <cd-msg-center></cd-msg-center>
    -->
  </div>
</div>
