import { Injectable } from '@angular/core';
import { BehaviorSubject }    from 'rxjs/BehaviorSubject';

@Injectable()
export class ProcessingService {
  private state = new BehaviorSubject<boolean>(false);
  private level = 0;

  stateObserver = this.state.asObservable();

  startProcess(): void {
    if (this.level === 0) {
      this.state.next(true);
    }
    this.level++;
  }

  stopProcess(): void {
    this.level--;
    if (this.level === 0) {
      this.state.next(false);
    }
  }
}
