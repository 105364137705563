import { Component } from '@angular/core';
import { Tenant } from './tenants.service';

@Component({
  selector: 'tenants',
  templateUrl: './tenants.component.html',
})
export class TenantsComponent {
  selectedTenant: Tenant;

  constructor() {
  }

  onSelect(tenant: Tenant): void {
    this.selectedTenant = tenant;
  }
}
