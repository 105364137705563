import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './auth.guard';

import { PagesComponent } from './pages.component';

const routes: Routes = [{
  path: 'pages',
  component: PagesComponent,
  canActivate: [ AuthGuard ], canActivateChild:  [ AuthGuard ],
  children: [
    {
        path: 'homepage',
        loadChildren: () => import('./homepage/homepage.module')
          .then(m => m.HomepageModule),
    },
    {
      path: 'feed',
      loadChildren: () => import('./feed/feed.module')
        .then(m => m.FeedModule),
    },
    {
      path: 'datasets',
      loadChildren: () => import('./datasets/datasets.module')
        .then(m => m.DatasetsModule),
    },
    {
      path: 'entities',
      loadChildren: () => import('./entities/entities.module')
        .then(m => m.EntitiesModule),
    },
    {
      path: 'resources',
      loadChildren: () => import('./resources/resources.module')
        .then(m => m.ResourcesModule),
    },
    {
      path: 'schemas',
      loadChildren: () => import('./schemas/schemas.module')
        .then(m => m.SchemasModule),
    },
    {
      path: 'scheduler',
      loadChildren: () => import('./scheduler/scheduler.module')
        .then(m => m.SchedulerModule),
    },
    {
      path: 'cache',
      loadChildren: () => import('./cache/cache.module')
          .then(m => m.CacheModule),
    },
    {
      path: 'pge',
      loadChildren: () => import('./pge/pge.module')
      .then(m => m.PgeModule),
    },
    {
      path: 'extended',
      loadChildren: () => import('./extended_data/extended_data.module')
        .then(m => m.ExtendedDataModule),
    },
    {
      path: 'erange',
      loadChildren: () => import('./erange_maintenance/erange_maintenance.module')
        .then(m => m.ErangeMaintenanceModule),
    },
    {
      path: 'vehicletree',
      loadChildren: () => import('./vehicle_tree/vehicle_tree.module')
          .then(m => m.VehicleTreeModule),
    },
    {
      path: 'trim',
      loadChildren: () => import('./trim_maintenance/trim_maintenance.module')
          .then(m => m.TrimMaintenanceModule),
    },
    {
      path: 'vehicletreedashboard',
      loadChildren: () => import('./vehicle_tree_dashboard/vehicle_tree_dashboard.module')
          .then(m => m.VehicleTreeDashboardModule),
    },
    {
      path: 'vehicletreefeed',
      loadChildren: () => import('./vehicle_tree_feed/vehicle_tree_feed.module')
          .then(m => m.VehicleTreeFeedModule),
    },
    {
      path: 'precon/vehicles',
      loadChildren: () => import('./precon/precon.module')
        .then(m => m.PreconModule),
    },
    {
      path: 'precon/dashboard',
      loadChildren: () => import('./precon_dashboard/precon_dashboard.module')
          .then(m => m.PreconDashboardModule),
    },
    {
      path: 'precon/feed',
      loadChildren: () => import('./precon_feed/precon_feed.module')
          .then(m => m.PreconFeedModule),
    },
    {
      path: 'ismpackages',
      loadChildren: () => import('./ismpackages/ism.module')
          .then(m => m.IsmModule),
    },
      {
      path: 'ismoptions',
      loadChildren: () => import('./ismoptions/ismoptions.module')
          .then(m => m.IsmOptionsModule),
    },
    {
      path: 'visualizer/configurations',
      loadChildren: () => import('./visualizer/visualizer.module')
        .then(m => m.VisualizerModule),
    },
    {
      path: 'visualizer/dashboard',
      loadChildren: () => import('./visualizer_dashboard/visualizer_dashboard.module')
        .then(m => m.VisualizerDashboardModule),
    },
    {
      path: 'visualizer/feed',
      loadChildren: () => import('./visualizer_feed/visualizer_feed.module')
        .then(m => m.VisualizerFeedModule),
    },
    {
      path: 'visualizer/migration',
      loadChildren: () => import('./visualizer_migration/visualizer_migration.module')
        .then(m => m.VisualizerMigrationModule),
    },
    {
      path: 'admin/events',
      loadChildren: () => import('./admin/events/events.module')
        .then(m => m.EventsModule),
    },
    {
      path: 'admin/tenants',
      loadChildren: () => import('./admin/tenants/tenants.module')
        .then(m => m.TenantsModule),
    },
    {
      path: 'admin/settings',
      loadChildren: () => import('./admin/settings/settings.module')
        .then(m => m.SettingsModule),
    },
    {
      path: 'admin/settings_comparison',
      loadChildren: () => import('./admin/settings_comparison/settings_comparison.module')
        .then(m => m.SettingsComparisonModule),
    },
    {
      path: 'admin/users',
      loadChildren: () => import('./admin/users/users.module')
        .then(m => m.UsersModule),
    },
    {
      path: 'admin/roles',
      loadChildren: () => import('./admin/roles/roles.module')
        .then(m => m.RolesModule),
    },
  ],
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module')
        .then(m => m.LoginModule),
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {
}
