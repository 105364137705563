<nb-card>
  <nb-card-header>
    <div class="header-content">
      <div>
      {{title}}
    </div>
    <div>
      <button class="close" aria-label="Close" (click)="close.emit($event)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  </nb-card-header>
  <nb-card-body>
    <ng-content></ng-content>
  </nb-card-body>
  <nb-card-footer>
    <ng-content select="footer"></ng-content>
  </nb-card-footer>
</nb-card>
