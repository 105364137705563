import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/**
 * NOTE: the '' route is not working as expected, see also workaround in app constructor.
 */
export const routes: Routes = [
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' }
];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, { useHash: true, enableTracing: false  });
