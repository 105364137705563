import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';


@Component({
  selector: 'cd-search-action',
  templateUrl: './cdSearchAction.html',
  styleUrls: ['./cdSearchAction.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CdSearchAction {
  @Output() search = new EventEmitter();
  @Input() storedSearchTerm = '';
}
