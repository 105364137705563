import { Injectable } from '@angular/core';
import { HttpResponse, HttpInterceptor, HttpRequest, HttpEvent, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { ProcessingService } from './processing.service'
import { Subject }    from 'rxjs/Subject';
import { Observable, throwError }    from 'rxjs';
import { map, catchError }    from 'rxjs/operators';

import { NbSpinnerService } from '@nebular/theme';

@Injectable()
export class GlobalState implements HttpInterceptor {

  private _data = new Subject<Object>();
  private _dataStream$ = this._data.asObservable();

  private _subscriptions: Map<string, Array<Function>> = new Map<string, Array<Function>>();

  constructor(private processingService: ProcessingService, private spinnerService: NbSpinnerService) {
    this._dataStream$.subscribe((data) => this._onEvent(data));
  }

  notifyDataChanged(event, value) {
    let current = this._data[event];
    if (current !== value) {
      this._data[event] = value;

      this._data.next({
        event: event,
        data: this._data[event]
      });
    }
  }

  subscribe(event: string, callback: Function) {
    let subscribers = this._subscriptions.get(event) || [];
    subscribers.push(callback);

    this._subscriptions.set(event, subscribers);
  }

  _onEvent(data: any) {
    let subscribers = this._subscriptions.get(data['event']) || [];

    subscribers.forEach((callback) => {
      callback.call(null, data['data']);
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.processingService.startProcess();

    return next.handle(req).pipe(
      map(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.processingService.stopProcess();
          }
          return event;
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          this.processingService.stopProcess();
          return throwError(error);
        }
      )
    );
  }
}
