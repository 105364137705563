import { Component, Input } from '@angular/core';
import { Tenant } from '../../tenants.service';

@Component({
  selector: 'tenants-details-general',
  templateUrl: './general.html',
})
export class General {

  @Input() tenant: Tenant;

  constructor() {
  }
}
