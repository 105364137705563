import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Subscription} from 'rxjs/Subscription';
import {Config} from '../../../config/config';
import {CdEntityService, CdSessionService, CdNotificationService} from '../../../theme-ext/services';

export class Tenant {
  id: string;
  name: string;
  description: string;
  builtin: boolean;
  changed: boolean;
  parent: Tenant;
}

export class AbstractTenantsService<T> extends CdEntityService<T> {

  constructor(sessionService: CdSessionService, notificationService: CdNotificationService) {
    super(sessionService, notificationService);
  }

  protected getTenantId(tenant: Tenant): string {
    let tenantId = undefined;
    if (tenant) {
      tenantId = tenant.id;
    }
    return this.mapTenantId(tenantId);
  }

  public mapTenantId(id: string): string {
    return super.encodePathId(id);
  }
}

const TENANT_SESSION: string = 'TENANT_SESSION';

@Injectable()
export class TenantsService extends AbstractTenantsService<Tenant> {

  private _selectedTenant = new BehaviorSubject<Tenant>(undefined);
  private _selectedTenantObservable = this._selectedTenant.asObservable();
  private childrenMap: { [id: string]: Tenant[] };

  constructor(sessionService: CdSessionService, notificationService: CdNotificationService) {
    super(sessionService, notificationService);
    this.clearChildrenMap();
    this.fillTenantWithStoredSession();
    sessionService.subscribeLoggedIn(loggedIn => {
      if (loggedIn === false) {
        this.clearChildrenMap();
        this.selectedTenant = undefined;
      }
    });
  }

  get selectedTenant(): Tenant {
    return this._selectedTenant.getValue();
  }

  set selectedTenant(tenant: Tenant) {
    if (tenant) sessionStorage.setItem(TENANT_SESSION, JSON.stringify(tenant));
    this._selectedTenant.next(tenant);
  }

  subscribeSelectedTenant(callback: (value: Tenant) => void): Subscription {
    let sub = this._selectedTenantObservable.subscribe(callback);
    return sub;
  }

  unsubscribeSelectedTenant(subscription: Subscription) {
    subscription.unsubscribe();
  }

  getOverview(): Promise<Tenant[]> {
    return super.list(Config.env().tenantsListServiceUrl);
  }

  loadTenant(tenantId: string, check: boolean = true) {
    tenantId = this.mapTenantId(tenantId);
    return super.load(Config.env().tenantsLoadServiceUrl, tenantId, check);
  }

  saveTenant(tenant: Tenant, storedId?: string) {
    let tenantId = this.getTenantId(tenant);
    let mappedStoreId = this.mapTenantId(storedId);
    this.clearChildrenMap();
    return super.save(Config.env().tenantsSaveServiceUrl, tenant, tenantId, mappedStoreId);
  }

  deleteTenant(tenant: Tenant) {
    let tenantId = this.getTenantId(tenant);
    this.clearChildrenMap();
    return super.delete(Config.env().tenantsDeleteServiceUrl, tenantId);
  }

  getChildren(tenant: Tenant): any {
    let tenantId = this.getTenantId(tenant);
    let cachedChildren = this.childrenMap[tenantId];
    if (cachedChildren) {
      return Promise.resolve(cachedChildren);
    }

    let url = this.buildUrl(Config.env().tenantsChildrenServiceUrl, {
      tenant: tenantId
    });
    return this.get(url)
      .then((response: any) => {
        let children = response as Tenant[];
        this.childrenMap[tenantId] = children;
        return children;
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  preselectTenant(tenant: Tenant): void {
    let tenantId = this.getTenantId(tenant);
    let url = this.buildUrl(Config.env().tenantsPreselectedTenantServiceUrl, {
      tenant: tenantId
    });
    this.get(url)
        .then((response: Tenant) => {
          if (response) {
            this.selectedTenant = response;
          }
        })
        .catch(error => {
          this.handleError(error);
        });
  }

  autoSelectSingleChild(tenant: Tenant, tryNb = 0): void {
    if (tryNb > 1) {
      return;
    }
    let tenantId = this.getTenantId(tenant);
    let tenantChildrenMap = this.childrenMap[tenantId];
    if (tenantChildrenMap && tenantChildrenMap.length == 1) {
      this.getChildren(tenantChildrenMap[0]).then(children => this.selectedTenant = tenantChildrenMap[0]);
    } else if (!tenantChildrenMap) {
      this.getChildren(tenant).then(children => {
        this.autoSelectSingleChild(tenant, ++tryNb);
      });
    }
  }

  fillTenantWithStoredSession = () => {
    const tenant = sessionStorage.getItem(TENANT_SESSION);
    if (!tenant) return;
    this.selectedTenant = JSON.parse(tenant);
  }

  getSelectedPath(): Tenant[] {
    let path: Tenant[] = [];
    let tenant = this.selectedTenant;
    while (tenant) {
      path.unshift(tenant);
      tenant = tenant.parent;
    }
    return path;
  }

  private clearChildrenMap() {
    this.childrenMap = {};
  }
}
