import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CdSessionService } from '../../../services/cdSession.service';

@Component({
  selector: 'cd-menu-item',
  templateUrl: './cdMenuItem.html',
  styleUrls: ['./cdMenuItem.scss']
})
export class CdMenuItem {

  @Input() menuItem: any;
  @Input() child: boolean = false;

  @Output() itemHover = new EventEmitter<any>();
  @Output() toggleSubMenu = new EventEmitter<any>();

  public constructor(private sessionService: CdSessionService) {
  }

  public onHoverItem($event): void {
    this.itemHover.emit($event);
  }

  public onToggleSubMenu($event, item): boolean {
    $event.item = item;
    this.toggleSubMenu.emit($event);
    return false;
  }

  public isHidden(): boolean {
    if (this.menuItem.hidden) {
      return true;
    }
    if (this.menuItem.permission !== undefined) {
      return !this.sessionService.hasPermission(this.menuItem.permission);
    }
    return false;
  }
}
