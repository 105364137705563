import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';


export const CD_MODAL_OPTIONS: NgbModalOptions = {size: 'lg', windowClass: 'dark-modal'};

@Component({
  selector: 'cd-modal',
  templateUrl: './cdModal.html',
  styleUrls: ['./cdModal.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CdModal {
  @Input() title: string;
  @Output() close = new EventEmitter();
}
