import {Injectable} from '@angular/core';
import {CdAbstractService} from './cdAbstractService.service';
import {CdSessionService} from './cdSession.service';
import {CdNotificationService} from "./cdNotificationService.service";


@Injectable()
export class CdEntityService<T> extends CdAbstractService {

  protected static EMPTY_PATH_ID: string = '-';

  constructor(sessionService: CdSessionService, notificationService: CdNotificationService) {
    super();
    this.sessionService = sessionService;
    this.http = sessionService.http;
    this.notificationService = notificationService;
  }

  getNoAuth(url: string): any {
    return this.getWithoutAuthorization(url)
      .then((response: any) => response as T[])
      .catch(error => {
        this.handleError(error);
      });
  }

  list(listUrl: string): any {
    return this.get(listUrl)
      .then((response: any) => response as T[])
      .catch(error => {
        this.handleError(error);
      });
  }

  load(loadUrl: string, entityId: string, check: boolean = true) {
    let url = this.buildUrl(loadUrl, this.getIdMap(loadUrl, entityId));
    return this._load(url, check);
  }

  _load(url: string, check: boolean = true) {
    return this.get(url)
      .then((response: any) => response as T)
      .catch(response => {
        if (check) {
          this.handleError(response);
        } else {
          return Promise.resolve(null);
        }
      });
  }

  save(saveUrl: string, entity: T, entityId: string, storedId?: string) {
    let id = storedId;
    if (id === undefined || id === CdEntityService.EMPTY_PATH_ID) {
      id = entityId;
    }
    let url = this.buildUrl(saveUrl, this.getIdMap(saveUrl, id));
    return this.post(url, entity)
      .then((response: any) => response as T)
      .catch(error => {
        this.handleError(error);
      });
  }

  _save(url: string, entity: T, rawErrorResponse = false) {
    return this.post(url, entity)
      .then((response: any) => response as T)
      .catch(error => {
        return rawErrorResponse ? Promise.reject(error) : this.handleError(error);
      });
  }

  _saveMultiple(url: string, entity: T[], rawErrorResponse = false) {
    return this.post(url, entity)
      .then((response: any) => response as T)
      .catch(error => {
        return rawErrorResponse ? Promise.reject(error) : this.handleError(error);
      });
  }

  deleteByUrl(deleteUrl: string) {
    return this._delete(deleteUrl);
  }

  delete(deleteUrl: string, entityId: string) {
    let url = this.buildUrl(deleteUrl, this.getIdMap(deleteUrl, entityId));
    return this._delete(url);
  }

  _delete(url: string) {
    return super.delete(url, undefined)
      .then(response => void 0)
      .catch(error => {
        return this.handleError(error);
      });
  }


  protected encodePathId(pathId: string): string {
    let encodedPathId = CdEntityService.EMPTY_PATH_ID;
    if (pathId) {
      encodedPathId = pathId.replace(/\//g, '+');
    }
    return encodedPathId;
  }

  protected decodePathId(encodedPathId: string): string {
    let pathId = undefined;
    if (encodedPathId) {
      pathId = encodedPathId.replace(/\+/g, '/');
    }
    return pathId;
  }
}
