import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { CdSessionService } from './cdSession.service';
import { Config } from '../../config/config';

@Injectable()
export class CdAuthGuardService implements CanActivate, CanActivateChild {
  constructor(private sessionService: CdSessionService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let url: string = state.url;
    return this.checkLogin(url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  checkLogin(url: string): boolean {
    if (!Config.env().securityEnabled) {
      return true;
    }
    if (this.sessionService.isLoggedIn()) {
      return true;
    }

    // Store the attempted URL for redirecting
    this.sessionService.redirectUrl = url;

    // Navigate to the login page with extras
    this.router.navigate(['/login']);
    return false;
  }
}
