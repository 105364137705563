import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { CdAsyncFormBuilder, CdFormBuilder } from '../../utils/cdForms';

@Component({
  template: ''
})
export abstract class CdForm<T, S> {
  formGroup: FormGroup;

  errorConfig: any;
  errors: any = {};

  protected initialValue: T;

  createForm(data: T, service?: S): FormGroup {
    this.initialValue = data;
    let formBuilder: FormBuilder;
    if (service !== undefined) {
      formBuilder = new CdAsyncFormBuilder<S>(service);
    } else {
      formBuilder = new CdFormBuilder();
    }
    return this.createFromGroup(data, formBuilder);
  }

  protected abstract createFormConfig(data: T): FormGroup;
  protected abstract getResultData(formValue: any): T;

  protected createFormValidator(data: T): ValidatorFn {
    return null;
  }

  protected createErrorConfig(data: T): any {
    return [];
  };

  protected createFromGroup(data: T, formBuilder: FormBuilder): FormGroup {
    this.errorConfig = this.createErrorConfig(data);
    let formConfig = this.createFormConfig(data);
    // let formValidator = this.createFormValidator(data);

    console.log('create-form-config', formConfig);
    this.formGroup = formConfig;
    // if (formValidator !== null) {
    //   this.formGroup = formBuilder.group(formConfig, {
    //     validator: formValidator
    //   });
    // } else {
    //   this.formGroup = formBuilder.group(formConfig);
    // }
    console.log('create-form-group', this.formGroup);
    this.formGroup.statusChanges.subscribe(value => this.onStatusChanged(value));
    this.formGroup.valueChanges.subscribe(value => this.onValueChanged(value));
    this.onStatusChanged(); // (re)set validation messages now

    return this.formGroup;
  }


  protected onStatusChanged(formValue?: any) {
    if (!this.formGroup) {
      return;
    }
    for (const field in this.errorConfig) {
      // clear previous error message (if any)
      this.errors[field] = '';
      const control = this.formGroup.get(field);
      if (control && control.dirty && !control.valid) {
        const messages = this.errorConfig[field];
        for (const key in control.errors) {
          this.errors[field] += messages[key] + ' ';
        }
      }
    }
  }

  protected onValueChanged(formValue?: any) {
    // overwrite me, if needed
  }
}
