import {Injectable} from '@angular/core';
import {CdAbstractService} from "./cdAbstractService.service";
import {Config} from "../../config/config";
import {CdSessionService} from "./cdSession.service";
import {CdNotificationService} from "./cdNotificationService.service";

@Injectable({
    providedIn: 'root'
})
export class CdSettingsProviderService extends CdAbstractService {
    constructor(sessionService: CdSessionService, notificationService: CdNotificationService) {
        super();
        this.sessionService = sessionService;
        this.http = sessionService.http;
        this.notificationService = notificationService;
    }

    getStageName(): Promise<string> {
        let url = this.buildUrl(Config.env().stageName, null);

        return super.get(url, {responseType: 'text'}).then(response => response.toUpperCase());
    }
}
