  <div class="control-input">
    <div class="control-input-label">
      <label class="control-label" [for]="for">{{label}}{{labelSeparator}}<b>{{boldLabel}}</b><b *ngIf="required" class="red-text">*</b>
      </label>
      <div *ngIf="info" class="info-icon">
        <nb-icon class="icon" icon="info"></nb-icon>
        <span class="info-text">{{info}}</span>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <div *ngIf="errors && errors[for]" class="alert alert-danger">
    {{ errors[for] }}
  </div>
