import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxUploaderModule } from 'ngx-uploader';
import { AppTranslationModule } from '../app.translation.module';
import { ThemeModule } from '../@theme/theme.module';

import {
  CdAction,
  CdBoolean,
  CdCard,
  CdControl,
  CdMenu,
  CdMenuItem,
  CdModal,
  CdModalConfirm,
  CdModalForm,
  CdMsgCenter,
  CdPageTop,
  CdSearchAction,
  CdSidebar,
  CdTable,
  CdTableCellBoolean,
  CdTableCellTristate,
  CdTableCellPreformatted, CheckboxControlComponent
} from './components';

import { CdPermissionPipe, CdSanitizeHtmlPipe } from './pipes';
import {
  CdAbstractService,
  CdAuthGuardService,
  CdAuthService,
  CdEntityService,
  CdMenuService, CdNotificationService,
  CdSessionService,
} from './services';
import {
  NbButtonModule,
  NbCardModule,
  NbLayoutModule,
  NbTreeGridModule,
  NbAccordionModule,
  NbToggleModule,
  NbIconModule,
} from '@nebular/theme';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CdTreeGrid } from './components/cd-tree-grid/cd-tree-grid.component';
import {Angular2SmartTableModule} from "angular2-smart-table";


const NGC_COMPONENTS = [
  CdAction,
  CdBoolean,
  CdCard,
  CdControl,
  CdMenu,
  CdMenuItem,
  CdModal,
  CdModalConfirm,
  CdModalForm,
  CdMsgCenter,
  CdPageTop,
  CdSearchAction,
  CdSidebar,
  CdTable,
  CdTableCellBoolean,
  CdTableCellTristate,
  CdTableCellPreformatted,
  CdTreeGrid,
  CheckboxControlComponent,
];

const NGC_DIRECTIVES = [
];

const NGC_PIPES = [
  CdPermissionPipe,
  CdSanitizeHtmlPipe
];

const NGC_SERVICES = [
  CdNotificationService,
  CdAbstractService,
  CdAuthService,
  CdAuthGuardService,
  CdEntityService,
  CdMenuService,
  CdSessionService
];

const NGC_VALIDATORS = [];

@NgModule({
    declarations: [
        ...NGC_PIPES,
        ...NGC_DIRECTIVES,
        ...NGC_COMPONENTS,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        // AppTranslationModule,
        NgxUploaderModule,
        ThemeModule,
        Angular2SmartTableModule,
        NbLayoutModule,
        NbCardModule,
        NbButtonModule,
        NbTreeGridModule,
        NbAccordionModule,
        NgSelectModule,
        NbToggleModule,
        NbIconModule
    ],
    exports: [
        ...NGC_PIPES,
        ...NGC_DIRECTIVES,
        ...NGC_COMPONENTS,
        ReactiveFormsModule,
        NgSelectModule,
    ]
})
export class NgcModule {
  static forRoot(): ModuleWithProviders<any> {
    return <ModuleWithProviders<any>> {
      ngModule: NgcModule,
      providers: [
        ...NGC_VALIDATORS,
        ...NGC_SERVICES
      ],
    };
  }
}
