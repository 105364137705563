import { Component } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { CdModalForm } from '../../../../../theme-ext/components/cdModalForm/cdModalForm.component';
import { AsyncFormControl, CdValidators } from '../../../../../theme-ext/utils/cdForms';
import { Tenant, TenantsService } from '../../tenants.service';


@Component({
  selector: 'tenants-edit',
  templateUrl: './edit.html'
})
export class Edit extends CdModalForm<Tenant, TenantsService> {
  create: boolean;
  inputTenant: Tenant;

  createFormConfig(tenant: Tenant): FormGroup {
    this.inputTenant = tenant;
    return new FormGroup({
      tenantId: new FormControl({value: tenant.id, disabled: true}),
      name: new FormControl({value: tenant.name, disabled: !this.create}, [CdValidators.id(), EditValidators.tenantExists]),
      description: new FormControl(tenant.description),
      builtin: new FormControl({value: tenant.builtin, disabled: true}),
      changed: new FormControl({value: tenant.changed, disabled: true}),
      parent: new FormControl(tenant.parent)
    });
  }

  createErrorConfig(tenant: Tenant) {
    let nameLabel = 'Name';
    return {
      name: {
        required: CdValidators.idRequiredError(nameLabel),
        minlength: CdValidators.idMinLengthError(nameLabel),
        maxlength: CdValidators.idMaxLengthError(nameLabel),
        pattern: CdValidators.idPatternError(nameLabel),
        tenantExists: CdValidators.idExistsError(nameLabel)
      }
    };
  }

  createFormValidator(tenant: Tenant): ValidatorFn {
    return null;
  }

  getResultData(formValue: any): Tenant {
    console.log('prepare-save-tenant', formValue, this.formGroup);
    const saveTenant: Tenant = {
      id: this.inputTenant.id as string,
      name: this.create ? formValue.name : this.inputTenant.name as string,
      description: formValue.description as string,
      builtin: this.inputTenant.builtin as boolean,
      changed: this.inputTenant.changed as boolean,
      parent: this.inputTenant.parent as Tenant
    };
    return saveTenant;
  }
}


export class EditValidators {
  static tenantExists(control: AsyncFormControl<TenantsService>): { [key: string]: any } {
    return new Promise(resolve => {
      console.log('tenantExists', control, control.service, control.value, control.initialValue);
      if (control.service === undefined || control.value === control.initialValue) {
        resolve(null);
        return;
      }
      let id = control.parent.controls['tenantId'].value;
      let i = id ? id.lastIndexOf('/') : -1;
      if (i !== -1) {
        id = id.substring(0, i + 1) + control.value;
      } else {
        id = control.value;
      }
      control.service.loadTenant(id, false).then(tenant => {
        if (tenant != null) {
          resolve({tenantExists: true});
        } else {
          // need to return null if ok
          resolve(null);
        }
      });
    });
  }
}
