import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Tenant, TenantsService } from '../tenants.service';
import {CdSessionService} from '../../../../theme-ext/services';
import { Router, Event, NavigationEnd } from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'tenants-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.scss']
})
export class TenantsSelectionComponent implements OnInit, OnDestroy {
  tenantPreselected: boolean = false;
  private routerEventsSubscription: Subscription;

  get selectedPath(): Tenant[] {
    return this.tenantsService.getSelectedPath();
  }

  get selectedTenant(): Tenant {
    return this.tenantsService.selectedTenant;
  }

  get getUrl(): String {
    return this.router.url;
  }

  get isSelectionForVisualizer(): boolean {
    return this.getUrl.includes('/visualizer/');
  }

  get isDomainTenantSelected(): boolean {
    if (!this.selectedTenant) {
      return false;
    }

    const tenantId = this.selectedTenant.id;
    return this.getNumSlashesFromTenantId(tenantId) === 2;
  }

  @Input()
  set selectedTenant(tenant: Tenant) {
    this.tenantsService.selectedTenant = tenant;
  }

  constructor(private tenantsService: TenantsService, private sessionService: CdSessionService, private router: Router) {
    sessionService.subscribeLoggedIn(loggedIn => {
      if (loggedIn === false) {
        this.tenantPreselected = false;
      } else if (loggedIn && !this.tenantPreselected) {
        tenantsService.preselectTenant(null);
        this.tenantPreselected = true;
      }
    });
  }

  ngOnInit() {
    this.routerEventsSubscription = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        const tenantId = this.selectedTenant.id;
        if (this.isSelectionForVisualizer && this.getNumSlashesFromTenantId(tenantId) > 2) {
          const tenantIdWithoutLocaleNode = tenantId
            .split('/')
            .slice(0, -1) // Remove last node (= locale node)
            .join('/');
          this.tenantsService.loadTenant(tenantIdWithoutLocaleNode)
            .then(tenant => {
              this.tenantsService.selectedTenant = tenant;
            });
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }

  onSelect(tenant: Tenant): void {
    this.selectedTenant = tenant;
  }

  private getNumSlashesFromTenantId(tenantId: string) {
    return tenantId.replace(/[^/]/g, '').length;
  }
}
