import { Component } from '@angular/core';
import {CdSettingsProviderService} from "../../../theme-ext/services/cdSettingsProvider.service";

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed [ngStyle]="headerColor?{'background-color': headerColor}:{}">
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column>

        <ng-content select="tenants-selection"></ng-content>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {
  headerColor = '';

  constructor(protected settingsProviderService: CdSettingsProviderService){
    settingsProviderService.getStageName().then((stageName) => {
      switch(stageName) {
        case 'LOCAL': {
          this.headerColor = '#fae1dd';
          break;
        }
        case 'INT': {
          this.headerColor = '#f8f3e8';
          break;
        }
        case 'DEV': {
          this.headerColor = '#d8e2db';
          break;
        }
      }
    });
  }
}
