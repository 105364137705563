import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { CdSessionService } from '../services/cdSession.service';

@Pipe({
  name: 'cdPermission'
})
export class CdPermissionPipe implements PipeTransform  {


  constructor(private sessionService: CdSessionService) {
  }

  transform(permission: string): SafeHtml {
    return this.sessionService.hasPermission(permission);
  }
}
