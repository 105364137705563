import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgcModule } from '../../../theme-ext/ngc.module';
import { ThemeModule } from '../../../@theme/theme.module';
import { Details } from './details/details.component';
import { General } from './details/general/general.component';
import { Edit } from './overview/edit/edit.component';
import { Overview } from './overview/overview.component';
import { TenantsSelectionComponent } from './selection/selection.component';
import { TenantsSelectionDropDownComponent } from './selection/selectionDropDown.component';
import { TenantsSelectionItemComponent } from './selection/selectionItem.component';

import { TenantsComponent } from './tenants.component';
import { TenantsService } from './tenants.service';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        ThemeModule,
        NgbModule,
        NgcModule,
        RouterModule.forChild([
            {
                path: '',
                component: TenantsComponent,
            },
        ])
    ],
    declarations: [
        TenantsComponent,
        TenantsSelectionComponent,
        TenantsSelectionItemComponent,
        TenantsSelectionDropDownComponent,
        Overview,
        Edit,
        Details,
        General
    ],
    providers: [
        TenantsService
    ],
    exports: [
        TenantsSelectionComponent
    ]
})
export class TenantsModule {

}
