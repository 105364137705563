import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CdForm } from '../cdForm/cdForm.component';

@Component({
  template: ''
})
export abstract class CdModalForm<T, S> extends CdForm<T, S> {
  title: string;

  constructor(protected activeModal: NgbActiveModal) {
    super();
  }

  init(title: string, data: T, service?: S) {
    console.log('init works for modal', data);
    this.title = title;
    super.createForm(data, service);
  }

  onSubmit(): void {
    this.onStatusChanged();
    if (this.formGroup.invalid) {
      return;
    }
    let data = this.getResultData(this.formGroup.value);
    this.activeModal.close(data);
  }

  onCancel(): void {
    this.activeModal.close(false);
  }
}
