import { NgModule } from '@angular/core';
import { NbMenuModule, NbSpinnerModule } from '@nebular/theme';

import { ThemeModule } from '../@theme/theme.module';
import { PagesComponent } from './pages.component';
import {AdminModule} from "./admin/admin.module";
import {FeedModule} from "./feed/feed.module";
import { PagesRoutingModule } from './pages-routing.module';
import { AuthGuard } from './auth.guard';
import { TenantsModule } from './admin/tenants/tenants.module';
import { NgcModule } from '../theme-ext/ngc.module';

@NgModule({
  imports: [
    ThemeModule,
    NbMenuModule,
    PagesRoutingModule,
    TenantsModule,
    NgcModule,
    NbSpinnerModule
    /* TODO: check, if module include is needed here */

  ],
  declarations: [
    PagesComponent,
  ],
  providers: [
    AuthGuard,
  ]
})
export class PagesModule {
}
