import {Injectable} from '@angular/core';
import { HttpHeaders, HttpClient} from '@angular/common/http';
import 'rxjs/add/operator/toPromise';
import {Config} from '../../config/config';
import {CdAbstractService} from './cdAbstractService.service';
import {CdNotificationService} from "./cdNotificationService.service";

export class AuthInfo {
  access_token: string;
  token_type: string;
  refresh_token: string;
  expires_in: number;
  scope: string;
  error: string;
  error_description: string;
}

const CLIENT_ID = 'clientapp';
const CLIENT_SECRET = '123456';

@Injectable()
export class CdAuthService extends CdAbstractService {

  constructor(http: HttpClient, notificationService: CdNotificationService) {
    super();
    this.http = http;
    this.notificationService = notificationService;
  }

  authenticate(userId: string, password: string): Promise<AuthInfo> {
    let self = this;
    let authenticateUrl = Config.env().authenticateUrl;
    let body = {
      password: password,
      username: userId,
      grant_type: 'password',
      scope: 'read write',
      client_secret: CLIENT_SECRET,
      client_id: CLIENT_ID
    };
    let encodedUserPassword = btoa(CLIENT_ID + ':' + CLIENT_SECRET);

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Accept', 'application/json')
      .set('Authorization', 'Basic ' + encodedUserPassword);

    let options = {
      headers: headers
    };

    // console.log('auth-service', authenticateUrl, options, this.encodeParams(body));
    return this.post(authenticateUrl, this.encodeParams(body), options)
      .then((response: any) => {
        //console.log('auth-service ok', response);
        let r = response as AuthInfo;
        return r;
      })
      .catch((response) => {
        // console.log('auth-service NOK', response);
        return Promise.reject(response as AuthInfo);
      });
  }
}
