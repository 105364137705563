const serverUrl: string = '';
const serverAdminUrl: string = serverUrl + '/admin';
const mockServerUrl: string = 'http://localhost:3000/mock';

const redirectUrl:string = '?redirect_uri=%23/pages/homepage';
const oauthUrl:string = serverUrl + '/oauth2/authorization/';

export const environment = {
  securityEnabled: true,
  production: true,
  normalLoginEnabled: false,

  // auth
  authenticateUrl: serverUrl + '/oauth/token',

  // session
  sessionLoginUrl: serverUrl + '/session/login',
  sessionLogoutUrl: serverUrl + '/session/logout',
  oauth2LogoutUrl: 'https://auth.bmwgroup.net/auth/oauth2/intranetb2x/connect/endSession?post_logout_redirect_uri=' + serverUrl + '&id_token_hint={idToken}',
  sessionLoginSocialToken: serverUrl + '/session/social_token',
  sessionNormalLoginEnabled: serverUrl + '/session/normal_login_enabled',

  // dataset
  datasetsListServiceUrl: serverAdminUrl + '/dataset',
  datasetsLoadServiceUrl: serverAdminUrl + '/dataset/{tenant}/{schema}/{version}',
  datasetsSaveServiceUrl: serverAdminUrl + '/dataset/{tenant}/{schema}/{version}',
  datasetsReactivateServiceUrl: serverAdminUrl + '/dataset/reactivate/{tenant}/{schema}/{version}',
  datasetsDeleteDuplicatesServiceUrl: serverAdminUrl + '/dataset/{tenant}/{schema}/{version}/duplicates',

  // events
  eventsListServiceUrl: serverAdminUrl + '/event',
  eventsLoadServiceUrl: serverAdminUrl + '/event/{event}',
  eventsSaveServiceUrl: serverAdminUrl + '/event/{event}',
  eventsDeleteServiceUrl: serverAdminUrl + '/event/{event}',

  // tenants
  tenantsListServiceUrl: serverAdminUrl + '/tenant',
  tenantsLoadServiceUrl: serverAdminUrl + '/tenant/{tenant}',
  tenantsSaveServiceUrl: serverAdminUrl + '/tenant/{tenant}',
  tenantsDeleteServiceUrl: serverAdminUrl + '/tenant/{tenant}',
  tenantsParentServiceUrl: serverAdminUrl + '/tenant/{tenant}/parent',
  tenantsChildrenServiceUrl: serverAdminUrl + '/tenant/{tenant}/children',
  tenantsPreselectedTenantServiceUrl: serverAdminUrl + '/tenant/{tenant}/preselected',
  tenantsSettingsCompareServiceUrl: serverAdminUrl + '/tenant/{tenant}/settings/compare',
  stageName: `${serverAdminUrl}/tenant/stageName`,

  // settings
  settingsListServiceUrl: serverAdminUrl + '/setting/{tenant}',
  settingsSaveServiceUrl: serverAdminUrl + '/setting/{tenant}/{setting}',
  settingsDeleteServiceUrl: serverAdminUrl + '/setting/{tenant}/{setting}',
  settingsExistsServiceUrl: serverAdminUrl + '/setting/-/{setting}/exists',
  settingValueByIdServiceUrl: serverAdminUrl + '/setting/{tenant}/{setting}',
  settingInfoByIdServiceUrl: serverAdminUrl + '/setting/{tenant}/setting/{setting}',

  // users
  usersListServiceUrl: serverAdminUrl + '/user',
  usersLoadServiceUrl: serverAdminUrl + '/user/{user}',
  usersSaveServiceUrl: serverAdminUrl + '/user/{user}',
  usersDeleteServiceUrl: serverAdminUrl + '/user/{user}',
  usersImpersonateServiceUrl: serverAdminUrl + '/user/impersonate?username={impersonateUserId}',

  // roles
  rolesListServiceUrl: serverAdminUrl + '/role',
  rolesLoadServiceUrl: serverAdminUrl + '/role/{role}',
  rolesSaveServiceUrl: serverAdminUrl + '/role/{role}',
  rolesDeleteServiceUrl: serverAdminUrl + '/role/{role}',
  rolesPermissionsServiceUrl: serverAdminUrl + '/role/permissions',
  rolesImportServiceUrl: serverAdminUrl + '/role/import',

  // schemas
  schemasListServiceUrl: serverAdminUrl + '/schema',
  schemasLoadServiceUrl: serverAdminUrl + '/schema/{schema}',
  schemasSaveServiceUrl: serverAdminUrl + '/schema/{schema}',
  schemasDeleteServiceUrl: serverAdminUrl + '/schema/{schema}',
  schemasTrafosServiceUrl: serverAdminUrl + '/schemas/resources/{schema}',
  schemasEntitiesServiceUrl: serverAdminUrl + '/schemas/entities/{schema}',

  // resources
  resourcesListServiceUrl: serverAdminUrl + '/resource',
  resourcesLoadServiceUrl: serverAdminUrl + '/resource/{resource}',
  resourcesSaveServiceUrl: serverAdminUrl + '/resource/{resource}',
  resourcesDeleteServiceUrl: serverAdminUrl + '/resource/{resource}',

  // entities
  entitiesSchemaListServiceUrl: serverUrl + '/data/{tenant}',
  entitiesDatasetListServiceUrl: serverUrl + '/data/{tenant}/{schema}',
  entitiesListServiceUrl: serverAdminUrl + '/entities/{tenant}/{schema}/{version}',
  entitiesLoadServiceUrl: serverAdminUrl + '/entity/{entity}',
  entitiesSaveServiceUrl: serverAdminUrl + '/entity/{entity}',
  entitiesDeleteServiceUrl: serverAdminUrl + '/entity/{entity}',

  // Model Matrix
  pdhModelMatrixVersion: `${serverUrl}/pdh/basics/v1.0/dataversion/{tenant}/{timestamp}`,
  pdhModelMatrix: `${serverUrl}/pdh/basics/v1.0/matrix/{tenant}/{dataversion}`,
  pdhModelMatrixLatest: `${serverUrl}/pdh/basics/v1.0/matrix/{tenant}/latest`,

  // feed
  feedProductDataImportServiceUrl: serverUrl + '/feed/productdata/{tenant}/{rangeCode}/{modelCode}/import',

  // extended data
  extendedProductDataImportServiceUrl: serverAdminUrl + '/extended/productdata/{tenant}/import',
  extendedProductDataListServiceUrl: serverAdminUrl + '/extended/productdata',

  //scheduler
  schedulerListJobsUrl: serverAdminUrl + '/scheduler/listjobs/{tenant}',
  schedulerOverviewJobsUrl: serverAdminUrl + '/scheduler/overviewQueue',
  schedulerOverviewDeleteJobUrl: serverAdminUrl + '/scheduler/overviewDeleteJob/{jobkey}',
  schedulerRunJobUrl: serverAdminUrl + '/scheduler/runJob/{tenant}',
  schedulerStopJobUrl: serverAdminUrl + '/scheduler/stopJob/{tenant}',
  schedulerStartJobUrl: serverAdminUrl + '/scheduler/startJob/{tenant}',
  schedulerDeleteJobStatusUrl: serverAdminUrl + '/scheduler/remove/{uuid}',
  schedulerScheduleJobUrl: serverAdminUrl + '/scheduler/startJob/{tenant}/{timestamp}',

  //cache
  clearCacheUrl: serverAdminUrl + '/cache/{tenant}/clear',

 //pge
  pgeConnectUrl: serverAdminUrl + '/pge/{tenant}/connect',

  // dataset cleanup
  cleanupDatasetUrl: serverAdminUrl + '/cleanup/{tenant}/{schema}/{version}',

  // precon feed
  feedPreconImportUrl: `${serverAdminUrl}/precons/{tenant}/feed`,

  // precon
  preconListUrl: `${serverAdminUrl}/precons/{tenant}`,
  preconSaveUrl: `${serverAdminUrl}/precons/{tenant}/vehicle`,
  preconDeleteUrl: `${serverAdminUrl}/precons/vehicle/{tenant}/{id}`,
  preconValidateUrl: `${serverAdminUrl}/precons/{tenant}/validate`,
  preconAssetsUrl: `/content/dam/bmw/common/preconfiguration.assets.json`,
  preconByIdUrl: `${serverAdminUrl}/precons/{tenant}/vehicle/{id}`,
  preconSeriesListUrl: `${serverUrl}/pdh/precons/v1.0/series/{tenant}`,
  preconRangesListUrl: `${serverUrl}/pdh/precons/v1.0/ranges/{tenant}/{seriesCode}`,
  preconVehiclesListUrl: `${serverUrl}/pdh/precons/v1.0/vehicles/{tenant}/{modelRangeCode}`,
  preconVehiclesListWithDraftsUrl: `${serverUrl}/pdh/precons/v1.0/vehiclesWithDrafts/{tenant}/{modelRangeCode}`,
  preconHistoryOfChangesUrl: `${serverAdminUrl}/precons/vehicle/history/{id}`,
  preconFeaturesDetailsUrl: `${serverAdminUrl}/precons/{tenant}/features`,
  preconConUrl: `${serverAdminUrl}/precons/{tenant}/conUrl`,
  preconPreviewBuildabilityUrl: `${serverAdminUrl}/precons/{tenant}/previewBuildability`,
  preconClearOldErrorUrl: `${serverAdminUrl}/precons/{tenant}/clearOldInError`,

  //overwrite
  overwriteTreeUrl: `${serverAdminUrl}/overwrite/tree/{tenant}`,
  overwriteAllUrl: `${serverAdminUrl}/overwrite/{tenant}/all/light`,
  overwriteCleanupVehicleTreeUrl: `${serverAdminUrl}/overwrite/{tenant}/all/cleanup`,
  overwriteCompareUcpOverwritten: `${serverAdminUrl}/overwrite/compare/{tenant}/{rangeCode}/{modelCode}`,
  overwriteSaveSerieUrl: `${serverAdminUrl}/overwrite/{tenant}/serie`,
  overwriteDeleteSerieUrl: `${serverAdminUrl}/overwrite/{tenant}/serie/{code}`,
  overwriteSerieHistoryOfChangesUrl: `${serverAdminUrl}/overwrite/{tenant}/serie/history/{code}`,
  overwriteSaveRangeUrl: `${serverAdminUrl}/overwrite/{tenant}/range`,
  overwriteDeleteRangeUrl: `${serverAdminUrl}/overwrite/{tenant}/range/{seriesCode}/{code}`,
  overwriteDeleteAssociationRangeUrl: `${serverAdminUrl}/overwrite/{tenant}/deleteAssociationForRange/{seriesCode}/{code}`,
  overwriteRangeHistoryOfChangesUrl: `${serverAdminUrl}/overwrite/{tenant}/range/history/{code}`,
  overwriteSaveVehicleUrl: `${serverAdminUrl}/overwrite/{tenant}/vehicle`,
  overwriteSaveTrimUrl: `${serverAdminUrl}/overwrite/{tenant}/trim`,
  overwriteDeleteVehicleUrl: `${serverAdminUrl}/overwrite/{tenant}/vehicle/{seriesCode}/{rangeCode}/{code}`,
  overwriteDeleteAssociationVehicleUrl: `${serverAdminUrl}/overwrite/{tenant}/deleteAssociationForVehicle/{seriesCode}/{rangeCode}/{code}`,
  overwriteDeleteTrimUrl: `${serverAdminUrl}/overwrite/{tenant}/trim/{vehicleCode}/{trimCode}`,
  overwriteVehicleHistoryOfChangesUrl: `${serverAdminUrl}/overwrite/{tenant}/vehicle/history/{code}`,
  overwriteVehicleTrimHistoryOfChangesUrl: `${serverAdminUrl}/overwrite/{tenant}/vehicleTrim/history/{code}/{trimCode}`,
  overwriteSeriesListUrl: `${serverAdminUrl}/overwrite/{tenant}/seriesList`,
  overwriteRangesListUrl: `${serverAdminUrl}/overwrite/{tenant}/rangesList/{serie}`,
  overwriteRangeByCodeUrl: `${serverAdminUrl}/overwrite/{tenant}/rangeByCode/{range}`,
  overwriteVehicleListUrl: `${serverAdminUrl}/overwrite/{tenant}/vehiclesList/{range}`,
  overwriteVehicleUrl: `${serverAdminUrl}/overwrite/{tenant}/vehicleByCodeAndRange/{rangeCode}/{modelCode}`,
  overwriteMergedVehicleUrl: `${serverAdminUrl}/overwrite/{tenant}/merged/vehicleByCodeAndRange/{rangeCode}/{modelCode}`,
  overwriteTrimListUrl: `${serverAdminUrl}/overwrite/{tenant}/trimsList/{model}`,
  overwriteTrimUrl: `${serverAdminUrl}/overwrite/{tenant}/trimByCodeAndVehicle/{vehicleCode}/{trimCode}`,
  overwriteMergedTrimUrl: `${serverAdminUrl}/overwrite/{tenant}/merged/trimByCodeAndVehicle/{vehicleCode}/{trimCode}`,
  overwriteUcpVehicleUrl: `${serverAdminUrl}/overwrite/{tenant}/ucpVehicleByCodeAndRange/{rangeCode}/{modelCode}`,
  overwriteUcpTrimUrl: `${serverAdminUrl}/overwrite/{tenant}/ucpTrimByCodeAndVehicle/{vehicleCode}/{trimCode}`,
  overwriteImportPosy: `${serverAdminUrl}/overwrite/{tenant}/importPosy`,
  overwriteFeedDataImportUrl: `${serverAdminUrl}/overwrite/{tenant}/feed`,
  technicalData2: `${serverUrl}/pdh/technicaldata/v2.0/model/{tenant}/latest/{modelCode}`,
  technicalData2UcpData: `${serverUrl}/pdh/technicaldata/v2.0/model/{tenant}/latest/{modelCode}?skipOverwritten=true`,
  overwriteToggleUseOverwrittenDataVehicleUrl: `${serverAdminUrl}/overwrite/{tenant}/toggleUseOverwrittenData/vehicle`,
  overwriteToggleUseOverwrittenDataTrimUrl: `${serverAdminUrl}/overwrite/{tenant}/toggleUseOverwrittenData/trim`,
  blacklistToggleSerieUrl: `${serverAdminUrl}/overwrite/{tenant}/toggleBlacklist/serie`,
  blacklistToggleRangeUrl: `${serverAdminUrl}/overwrite/{tenant}/toggleBlacklist/range`,
  blacklistToggleVehicleUrl: `${serverAdminUrl}/overwrite/{tenant}/toggleBlacklist/vehicle`,
  blacklistToggleTrimUrl: `${serverAdminUrl}/overwrite/{tenant}/toggleBlacklist/trim`,
  eRange: `${serverAdminUrl}/overwrite/{tenant}/erange/{modelCode}`,
  isActivateValidation: `${serverAdminUrl}/tenant/{tenant}/is_activate_validation`,
  isUcpMarket: `${serverAdminUrl}/tenant/{tenant}/is_ucp_market`,
  isFsmMarket: `${serverAdminUrl}/tenant/{tenant}/is_fsm_market`,
  isIgnoreBreakInheritance: `${serverAdminUrl}/tenant/{tenant}/ignoreBreakInheritance`,
  masterTenant: `${serverAdminUrl}/tenant/{tenant}/master_tenant`,
  overwritePosyModelValidateUrl: `${serverAdminUrl}/overwrite/{tenant}/validatePosyModel`,
  overwriteVehicleValidateUrl: `${serverAdminUrl}/overwrite/{tenant}/validateVehicle`,
  overwriteRangeValidateUrl: `${serverAdminUrl}/overwrite/{tenant}/validateRange`,
  overwriteSerieValidateUrl: `${serverAdminUrl}/overwrite/{tenant}/validateSerie`,
  overwriteTrimValidateUrl: `${serverAdminUrl}/overwrite/{tenant}/validateTrim`,

  //trims
  trimTreeUrl: `${serverAdminUrl}/trims/tree/{tenant}`,
  trimSaveUrl: `${serverAdminUrl}/trims/{tenant}`,
  trimAssetsUrl: `/content/dam/MINI/common/trim-background.assets.json`,

  // precon setting
  settingsValueByIdServiceUrl: serverAdminUrl + '/precons/{tenant}/{setting}/value',

  //precon dashboard
  preconDashboardList: `${serverAdminUrl}/precons/{tenant}/flat`,

  //Erange
  erouteinfoListServiceUrl: `${serverAdminUrl}/erange/erouteinfos`,
  erouteinfoSaveServiceUrl: `${serverAdminUrl}/erange/erouteinfos/{modelCode}`,
  erouteinfoDeleteServiceUrl: `${serverAdminUrl}/erange/erouteinfos/{modelCode}`,

   //ism
   ismOptionsByIdUrl: `${serverAdminUrl}/ism/options/{tenantId}/{rangeCode}`,
   ismPackagesByIdUrl: `${serverAdminUrl}/ism/packages/{tenantId}/{rangeCode}`,
   ismPackageOptionsByIdUrl: `${serverAdminUrl}/ism/packageOptions/{tenantId}/{rangeCode}/{packageCode}`,
   ismSeriesIdUrl: `${serverAdminUrl}/ism/series/{tenantId}`,
   ismRangesIdUrl: `${serverAdminUrl}/ism/ranges/{tenantId}/{serieCode}`,
   ismSalesGroupByIdUrl: `${serverAdminUrl}/ism/salesGroup/{tenantId}/{rangeCode}`,
   ismSalesGroupOptionsByIdUrl: `${serverAdminUrl}/ism/salesGroupOptions/{tenantId}/{rangeCode}/{salesGroupCode}`,



  // visualizer
  visualizerListUrl: `${serverAdminUrl}/visualizer/{tenant}/list/variant`,
  visualizerSaveUrl: `${serverAdminUrl}/visualizer/{tenant}/variant`,
  visualizerDeleteUrl: `${serverAdminUrl}/visualizer/variant/{id}`,
  visualizerValidateUrl: `${serverAdminUrl}/visualizer/{tenant}/validate`,
  visualizerByIdUrl: `${serverAdminUrl}/visualizer/{tenant}/variant/{id}`,
  visualizerOptionsByModelRange: `${serverAdminUrl}/visualizer/options/{tenant}/{modelRangeCode}/list`,
  visualizerSeriesListUrl: `${serverUrl}/pdh/visualizer/series/{tenant}`,
  visualizerRangesListUrl: `${serverUrl}/pdh/visualizer/ranges/{tenant}/{seriesCode}`,
  visualizerVehiclesListUrl: `${serverUrl}/pdh/visualizer/variants/{tenant}/{modelRangeCode}`,
  visualizerSocVariantsListUrl: `${serverUrl}/pdh/visualizer/soc_variants/{tenant}`,

  visualizerMarketingTextsUrl: `${serverAdminUrl}/visualizer/{tenantId}/localisations/marketing-texts`,
  visualizerOverriddenVehicleTextsUrl: `${serverAdminUrl}/visualizer/{tenantId}/localisations/options`,
  visualizerVehicleOptionsUrl: `${serverAdminUrl}/visualizer/{tenantId}/vehicles/options`,
  visualizerVehicleAvailablePackagesUrl: `${serverAdminUrl}/visualizer/{tenantId}/vehicles/packages`,
  visualizerConstructibilityCheckUrl: `${serverAdminUrl}/visualizer/{tenant}/constructibility-check`,
  visualizerConstructibilityAddElementsBulkUrl: `${serverAdminUrl}/visualizer/{tenantId}/validate/{configurationState}/add-bulk/{elements}`,

  // visualizer setting
  visualizerGetUcpContextServiceUrl: serverAdminUrl + '/visualizer/{tenant}/context',
  visualizerTenantSettingsServiceUrl: serverAdminUrl + '/visualizer/{tenantId}/settings',

  // visualizer snapshot (version history)
  visualizerSaveSnapshotUrl: `${serverAdminUrl}/visualizer/{tenantId}/save-snapshot/{series}/{modelRange}`,
  visualizerGetSnapshotUrl: `${serverAdminUrl}/visualizer/{tenantId}/get-snapshot/{series}/{modelRange}/{snapshotDate}`,
  visualizerGetSnapshotsUrl: `${serverAdminUrl}/visualizer/{tenantId}/get-snapshots/{series}/{modelRange}`,
  visualizerUpdateSnapshotDescription: `${serverAdminUrl}/visualizer/update-snapshot`,
  visualizerValidateAndSaveUrl: `${serverAdminUrl}/visualizer/{tenant}/variants/bulk-save`,

  // visualizer dashboard
  visualizerDashboardList: `${serverAdminUrl}/visualizer/{tenant}/list/flat/variant`,

  // Visualizer create missing entries
  visualizerCreateMissingEntries: `${serverAdminUrl}/visualizer/{tenantId}/create-series-ranges`,
  // Visualizer should create missing entries check
  visualizerShouldCreateMissingEntriesCheck: `${serverAdminUrl}/visualizer/{tenantId}/should-create-series-ranges`,

  //services provided through aem integrated page
  aemJwtUser: serverUrl + '/aem/jwt/user',
  aemJwtToken: serverUrl + '/aem/jwt/token',
  aemJwtTenant: serverUrl + '/aem/jwt/tenant',

  //oauth
  bmwUrl: oauthUrl + 'bmw' + redirectUrl,
};
