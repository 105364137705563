  <div class="checkbox-control-input">
    <ng-content></ng-content>

    <label class="visualizer-checkbox-mark" [for]="for">
        <span class="checkmark">
          <div class="checkmark_stem"></div>
          <div class="checkmark_kick"></div>
        </span>
    </label>
    <label class="control-label" [for]="for">{{label}}
    </label>
  </div>
  <div *ngIf="errors && errors[for]" class="alert alert-danger">
    {{ errors[for] }}
  </div>
