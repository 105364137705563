import {
  AbstractControl, AsyncValidatorFn, FormArray, FormBuilder, FormControl, FormGroup,
  ValidatorFn, Validators
} from '@angular/forms';

export class CdFormBuilder extends FormBuilder {

  control(formState: Object, validator?: ValidatorFn | ValidatorFn[],
          asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[]): FormControl {
    return new CdFormControl(formState, validator, asyncValidator);
  }
}


export class CdAsyncFormBuilder<S> extends FormBuilder {

  constructor(private service: S) {
    super();
  }

  control(formState: Object, validator?: ValidatorFn | ValidatorFn[],
          asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[]): FormControl {
    let control: FormControl;
    if (asyncValidator !== undefined) {
      control = new AsyncFormControl(this.service, formState, validator, asyncValidator);
    } else {
      control = new CdFormControl(formState, validator, asyncValidator);
    }
    return control;
  }

  array(controlsConfig: any[], validator?: ValidatorFn, asyncValidator?: AsyncValidatorFn): FormArray {
    let control: FormArray = super.array(controlsConfig, validator, asyncValidator);
    if (asyncValidator !== undefined) {
      const controls: AbstractControl[] = [];
      for (let i = 0; i < control.length; i++) {
        let c = control.at(i);
        controls.push(c);
      }
      control = new AsyncFromArray(this.service, controls, validator, asyncValidator);
    }
    return control;
  }

}

export class CdFormControl extends FormControl {
  initialValue: any;

  constructor(formState?: any,
              validator?: ValidatorFn | ValidatorFn[],
              asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[]) {
    super(formState, validator, asyncValidator);
    this.initialValue = formState;
    // take care for disabled control specs
    /*
    if (formState !== undefined && formState.hasOwnProperty('value') && formState.hasOwnProperty('disabled')) {
      this.initialValue = formState.value;
    }
    */
  }
}


export class AsyncFormControl<S> extends CdFormControl {
  service: S;

  constructor(service: S,
              formState?: any,
              validator?: ValidatorFn | ValidatorFn[],
              asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[]) {
    super(formState, validator, asyncValidator);
    this.service = service;
  }
}

export class AsyncFromArray<S> extends FormArray {
  service: S;

  constructor(service: S, controls: AbstractControl[], validator?: ValidatorFn, asyncValidator?: AsyncValidatorFn) {
    super(controls, validator, asyncValidator);
    this.service = service;
  }
}


export class CdValidators {
  static id(): ValidatorFn {
    return Validators.compose([
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(32),
      Validators.pattern('[A-Za-z][A-za-z0-9-_]*')
    ]);
  }

  static idRequiredError(label: string): string {
    return label + ' is required.';
  }

  static idMinLengthError(label: string): string {
    return label + ' must have at least 2 characters.';
  }

  static idMaxLengthError(label: string): string {
    return label + ' must have not more than 32 characters.';
  }

  static idPatternError(label: string): string {
    return label + ' must start with a letter and then can have letters, digits, \'_\' and \'-\'.';
  }

  static idExistsError(label: string): string {
    return label + ' already exists, please choose another one.';
  }
}


