import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'cd-control',
  templateUrl: './cdControl.html',
  styleUrls: ['./cdControl.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CdControl {
  @Input() for;
  @Input() label;
  @Input() boldLabel;
  @Input() labelSeparator;
  @Input() errors;
  @Input() required;
  @Input() info;
}
