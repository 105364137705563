import { Injectable } from '@angular/core';
import { Config } from '../../../config/config';
import { AbstractTenantsService } from '../tenants/tenants.service';
import { CdEntityService } from '../../../theme-ext/services/cdEntity.service';
import {CdNotificationService, CdSessionService} from '../../../theme-ext/services';
import {Observable} from 'rxjs';

export class Setting {
  id: string;
  description: string;
  builtin: boolean;
  changed: boolean;
  value: string;
  builtinValue: string;
}

@Injectable()
export class SettingsService extends AbstractTenantsService<Setting> {
  constructor(sessionService: CdSessionService, notificationService: CdNotificationService) {
    super(sessionService, notificationService);
  }

  getOverview(tenantId: string): Promise<Setting[]> {
    let url = this.buildUrl(Config.env().settingsListServiceUrl, {
      tenant: this.mapTenantId(tenantId)
    });
    return super.list(url);
  }

  saveSetting(tenantId: string, setting: Setting, storedId?: string) {
    let url = this.buildUrl(Config.env().settingsSaveServiceUrl, {
      tenant: this.mapTenantId(tenantId),
      setting: this.encodeSettingId(storedId ? storedId : setting.id)
    });
    return super._save(url, setting);
  }

  deleteSetting(tenantId: string, setting: Setting) {
    let url = this.buildUrl(Config.env().settingsDeleteServiceUrl, {
      tenant: this.mapTenantId(tenantId),
      setting: this.encodeSettingId(setting.id)
    });
    return super._delete(url);
  }

  getSettingValueById(tenantId: string, settingId: string): Observable<any> {
    const url = this.buildUrl(Config.env().settingValueByIdServiceUrl, {
      tenant: this.mapTenantId(tenantId),
      setting: this.encodeSettingId(settingId)
    });
    return super.getObservable(url, {responseType: 'text'});
  }

  exists(settingId: string): Promise<boolean> {
    let url = this.buildUrl(Config.env().settingsExistsServiceUrl, {
      setting: this.encodeSettingId(settingId)
    });
    return super.get(url).then(response => false); // TODO: clarify return value
  }

  private encodeSettingId(settingId: string): string {
    let encodedSettingId = CdEntityService.EMPTY_PATH_ID;
    if (settingId) {
      encodedSettingId = settingId.replace(/\./g, '+');
    }
    return encodedSettingId;
  }

}
