import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'checkbox-control',
  templateUrl: 'checkbox-control.html',
  styleUrls: ['./checkbox-control.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CheckboxControlComponent {
  @Input() for;
  @Input() label;
  @Input() errors;
}
