<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">WEB DATA HUB (WDH) {{stageName}}</a>
  </div>
</div>

<div class="header-container">
  <label class="label" class="session-expired"  *ngIf="sessionExpired">Session Expired! Please refresh the page.</label>
  <nb-actions size="small">
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user *ngIf="user !== null" [nbContextMenu]="userMenu"
               [name]="user?.id">
      </nb-user>
      <button *ngIf="user === null" nbButton status="primary" (click)="navigateLogin()">Login</button>
    </nb-action>
  </nb-actions>
</div>
