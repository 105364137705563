import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  NbGetters,
  NbSortDirection,
  NbSortRequest,
  NbTreeGridDataService,
  NbTreeGridDataSource,
  NbTreeGridDataSourceBuilder,
  NbTreeGridFilterService,
  NbTreeGridService,
  NbTreeGridSortService
} from '@nebular/theme';
import { Observable } from 'rxjs';
import { CdTreeGridColumn } from './models/tree-grid-column.model';
import {CustomTreeGridFilterService} from "./custom-service/cd-tree-grid.cutom-filter-service";

@Component({
  selector: 'cd-tree-grid',
  templateUrl: './cd-tree-grid.component.html',
  styleUrls: ['./cd-tree-grid.component.scss']
})
export class CdTreeGrid implements OnInit {

  @Input() expanderColumn: CdTreeGridColumn[] = [];
  @Input() expanderRow: String;
  @Input() expandedNodes = [];
  @Input() expandAll = false;
  @Input() addChildRow: String;
  @Input() columns: CdTreeGridColumn[] = [];
  @Input() canWriteRow: String;
  @Input() canAddRow: boolean;
  @Input() hideDelete: boolean;
  @Input() hideDuplicate: boolean;
  @Input() undoEnabled: boolean = false;
  @Input() blacklistEnabled: boolean = false;
  @Input() excelDownloadEnabled: boolean = false;
  @Input() snapshotEnabled: boolean = false;
  @Input() shouldShowSnapshotDownloadFn: Function;
  @Input() getSnapshotDateFn: Function;
  @Input() isVisualizer: boolean;
  @Input() useToggleEnabled: boolean = false;
  @Input() deleteAssociationEnabled: boolean = false;

  @Output() select = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() duplicate = new EventEmitter();
  @Output() add = new EventEmitter();
  @Output() saveSnapshot = new EventEmitter();
  @Output() openSnapshotModal = new EventEmitter();
  @Output() undo = new EventEmitter();
  @Output() blacklist = new EventEmitter();
  @Output() compare = new EventEmitter();
  @Output() useToggle = new EventEmitter();
  @Output() expandedNodesChange = new EventEmitter();
  @Output() deleteAssociation = new EventEmitter();
  @Output() openDownloadExcelModal = new EventEmitter();

  actionColumns: CdTreeGridColumn[] =  [ { title:'Actions', property: 'actions', labelFunction: undefined}];
  allColumns: CdTreeGridColumn[];
  allColumnsPropertiesOnly: string[];
  dataSource: NbTreeGridDataSource<any>;
  sortColumn: string;
  sortDirection: NbSortDirection = NbSortDirection.NONE;
  selectedRow: string;

  constructor(private dataSourceBuilder: NbTreeGridDataSourceBuilder<any>) {
    const filterService = new CustomTreeGridFilterService<any>();
    const sortService = new NbTreeGridSortService<any>();
    const treeGridService = new NbTreeGridService<any>();
    const treeGridDataService = new NbTreeGridDataService<any>();
    this.dataSourceBuilder = new NbTreeGridDataSourceBuilder<any>(filterService, sortService, treeGridService, treeGridDataService);
  }

  ngOnInit(): void {
    this.allColumns = [ ...this.expanderColumn, ...this.columns, ...this.actionColumns ];
    this.allColumnsPropertiesOnly = this.allColumns.map(a => a.property);

    console.log('columns', this.allColumns);
    console.log('columns props', this.allColumnsPropertiesOnly);
  }

  check(data: any){
    console.log('my-data', data);
  }

  public setValues(data: Observable<any>) {
    const getters: NbGetters<any, any> = {
      dataGetter: (node: any) => node,
      childrenGetter: (node: any) => node.childEntities || undefined,
      expandedGetter: (node: any) => !!node.expander,
    };

    data.subscribe((values) => {
      this.setExpandedNodes(values);
      this.dataSource = this.dataSourceBuilder.create(values, getters);
      console.log('datasource', this.dataSource);
    });
  }

  setExpandedNodes(values, expandedId = 1) {
    values.forEach(value => {
      value.expandedId = expandedId++;
      value.expander = this.expandedNodes.includes(value.expandedId) || this.expandAll;
      if (this.expandAll) this.expandedNodes.push(value.expandedId);
      if (value.childEntities?.length) expandedId = this.setExpandedNodes(value.childEntities, expandedId);
    });
    return expandedId;
  }

  public filterData(search: string) {
    // console.log('filter from outside', search);
    this.dataSource.filter(search);
  }

  updateSort(sortRequest: NbSortRequest): void {
    this.sortColumn = sortRequest.column;
    this.sortDirection = sortRequest.direction;
  }

  getSortDirection(column: string): NbSortDirection {
    if (this.sortColumn === column) {
      return this.sortDirection;
    }
    return NbSortDirection.NONE;
  }

  getShowOn(index: number) {
    const minWithForMultipleColumns = 400;
    const nextColumnStep = 100;
    return minWithForMultipleColumns + (nextColumnStep * index);
  }

  selectAction(row: any): void {
    this.selectedRow = row.id;
    this.select.emit(row);
  }

  editAction(event: any, row: any): void {
    // console.log('edit', row);
    this.edit.emit(row);
    event.stopPropagation();
  }

  deleteAction(event: any, row: any): void {
    this.delete.emit(row);
    event.stopPropagation();
  }

  addAction(event: any, row?: any): void {
    this.add.emit(row);
    event.stopPropagation();
  }

  duplicateAction(event: any, row: any): void {
    this.duplicate.emit(row);
    event.stopPropagation();
  }

  hasChildEntities(event: any) {
    return event.childEntities?.length;
  }

  shouldHideUndo(data: any) {
    return !data.overwritten;
  }

  showUseToggle(data: any) {
    return data.useOverwrittenData !== undefined && !data.manual && data.overwritten;
  }

  deleteAssociationAllowed(data: any) {
    return (this.deleteAssociationEnabled || (data.associatedWith?.length > 0 && data.associatedWith.includes(data.parentCode))) && !data.manual;
  }

  excelDownloadAction(event: any, row: any): void {
    this.openDownloadExcelModal.emit(row);
    event.stopPropagation();
  }

  shouldHideSnapshotUI(rowData: any) {
    return !this.snapshotEnabled || rowData.code === 'SOC';
  }
  saveSnapshotAction(event: any, row: any): void {
    this.saveSnapshot.emit(row);
    event.stopPropagation();
  }

  openSnapshotModalAction(event: any, row: any): void {
    this.openSnapshotModal.emit(row);
    event.stopPropagation();
  }

  getSnapshotDate(row) {
    return this.getSnapshotDateFn(row?.snapshotDate)?.YYYY_MM_DD || '';
  }

  undoAction(event: any, row: any): void {
    this.undo.emit(row);
    event.stopPropagation();
  }

  deleteAssociationAction(event: any, row: any): void {
    this.deleteAssociation.emit(row);
    event.stopPropagation();
  }

  blacklistAction(event: any, data: any): void {
    this.blacklist.emit(data);
    event.stopPropagation();
  }

  compareAction(event: any, data: any): void {
    this.compare.emit(data);
    event.stopPropagation();
  }

  useToggleAction(event: any, data: any): void {
      this.useToggle.emit(data);
      event.stopPropagation();
  }


  toggleAction(row: any): void {
    this.expandedNodes = !row.expanded ?
                          this.expandedNodes.concat(row.data.expandedId) :
                          this.expandedNodes.filter(node => node !== row.data.expandedId);

    this.expandedNodesChange.emit(this.expandedNodes);
  }

}
