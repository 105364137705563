import { NbTreeGridFilterService } from '@nebular/theme';

export class CustomTreeGridFilterService<T> extends NbTreeGridFilterService<T> {

    protected filterPredicate(data: T, searchQuery: string): boolean {
        const preparedQuery = searchQuery.trim().toLocaleLowerCase();
        
        for (const val of Object.values(data)) {
            const prepVal = this.prepValue(val);
            const preparedVal = `${prepVal}`.trim().toLocaleLowerCase();
            
            if (preparedVal.includes(preparedQuery)) {
                return true;
            }
        }

        return false;
    }

    prepValue(value): string[] {
        const x = [];
        const isObject = typeof value === 'object' && value !== null;
        
        if (!isObject) {
            x.push(value);
        } else {
            for (const val of Object.values(value)) {
                x.push(this.prepValue(val));
            }
        }

        return x;
    }
}
